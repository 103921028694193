import React from "react";
import { useTeams } from "../../hooks/useTeams";
import { FaTrophy, FaTimesCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Leaderboard: React.FC = () => {
  const { data: teams, isLoading, error } = useTeams();
  const navigate = useNavigate();

  if (isLoading)
    return <div className="text-center text-white">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500">Failed to load teams.</div>
    );

  const handleTeamClick = (teamId: string) => {
    navigate(`/team/${teamId}`);
  };

  // Sort teams by wins (and losses as a tiebreaker if needed)
  const sortedTeams = teams
    ? [...teams].sort((a, b) => b.wins - a.wins || a.losses - b.losses)
    : [];

  return (
    <div className="flex flex-col justify-start">
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="bg-gradient-to-r from-purple-500 to-pink-700 text-white py-10 text-center rounded-lg mb-8 w-full"
      >
        <h2 className="text-4xl font-bold">Rise to the Top!</h2>
        <p className="mt-4 text-lg">
          Track your progress, challenge rivals, and see who’s dominating the
          Prime Championship Circuit. Only the best make it here!
        </p>
      </motion.div>

      {/* Leaderboard */}
      <div className="p-6 max-w-4xl w-full mx-auto bg-gradient-to-b from-gray-800 via-gray-900 to-black rounded-lg shadow-2xl border border-purple-500">
        <h1 className="text-4xl font-extrabold text-center mb-8 text-white">
          Leaderboard
        </h1>
        <ul className="list-none space-y-4">
          {sortedTeams.map((team: any, index: any) => (
            <li
              key={index}
              onClick={() => handleTeamClick(team.id)}
              className="flex items-center bg-base-200 hover:bg-primary/10 transition-colors shadow-lg p-5 rounded-xl border border-primary cursor-pointer"
            >
              {/* Rank */}
              <span className="text-2xl font-bold text-primary rounded-full w-10 h-10 flex items-center justify-center mr-4">
                {index + 1}
              </span>

              {/* Team Logo */}
              <img
                src={
                  team.team_logo
                    ? team.team_logo
                    : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
                }
                alt={`${team.name} logo`}
                className="w-14 h-14 rounded-full border-2 border-primary object-cover mr-4"
              />

              {/* Team Details */}
              <div className="flex-1">
                <h3 className="text-2xl font-semibold text-white">
                  {team.name}
                </h3>
                <div className="flex space-x-4 mt-2">
                  <span className="flex items-center text-lg font-semibold">
                    <FaTrophy size={15} className="text-yellow-500 mr-2" />
                    Wins: {team.wins}
                  </span>
                  <span className="flex items-center text-lg font-semibold">
                    <FaTimesCircle size={15} className="text-red-500 mr-2" />
                    Losses: {team.losses}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;
