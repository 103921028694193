import React from "react";

interface ProfileDetailsProps {
  username: string;
  steamId: string;
  avatarUrl: string;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  username,
  steamId,
  avatarUrl,
}) => {
  return (
    <div className="flex items-center mb-2 mt-6">
      <img
        src={avatarUrl}
        alt="Avatar"
        className="w-16 h-16 rounded-full mb-4 border-2 border-primary ring-2 ring-offset-4 ring-offset-gray-900 shadow-lg object-cover"
      />
      <div>
        <h2 className="text-4xl font-bold ml-4">{username}</h2>
      </div>
    </div>
  );
};

export default ProfileDetails;
