import React from "react";
import { Button as HeadlessButton } from "@headlessui/react";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "success" | "danger";
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = "button",
  variant = "primary",
}) => {
  // Custom styles for different variants
  const baseStyle = "font-semibold py-2 px-4 rounded-md focus:outline-none";
  const variantStyles = {
    primary: "bg-blue-500 text-white hover:bg-blue-600",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    success: "bg-green-600 text-white hover:bg-green-800",
    danger: "bg-red-500 text-white hover:bg-red-800",
  };

  return (
    <HeadlessButton
      as="button"
      type={type}
      onClick={onClick}
      className={`${baseStyle} ${variantStyles[variant]}`}
    >
      {children}
    </HeadlessButton>
  );
};

export default Button;
