import React, { useState, useEffect } from "react";
import { FaTrophy, FaSkull } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "../tooltips/Tooltip";

interface RecentMatchesProps {
  matches?: Array<any>;
  username?: string;
  setGlobalLoading: (loading: boolean) => void; // Add setGlobalLoading as a prop
}

const heroMap: { [key: number]: string } = {
  1: "Countess",
  2: "Crunch",
  3: "Dekker",
  4: "Drongo",
  5: "Feng Mao",
  6: "The Fey",
  7: "Gadget",
  8: "Gideon",
  9: "Grux",
  10: "Howitzer",
  11: "Kallari",
  12: "Khaimera",
  13: "Lt. Belica",
  14: "Murdock",
  15: "Muriel",
  16: "Narbash",
  17: "Rampage",
  18: "Riktor",
  19: "Sevarog",
  20: "Sparrow",
  21: "Steel",
  22: "Revenant",
  23: "Shinbi",
  24: "Kira",
  25: "Phase",
  27: "Morigesh",
  29: "Greystone",
  30: "Twinblast",
  31: "Zarus",
  39: "Serath",
  41: "Wraith",
  42: "Iggy & Scorch",
  44: "Kwang",
  49: "Argus",
  52: "Grim.exe",
  53: "Aurora",
  54: "Terra",
  55: "Zinx",
  57: "Skylar",
  58: "Yin",
};

const RecentMatches: React.FC<RecentMatchesProps> = ({
  matches,
  username,
  setGlobalLoading,
}) => {
  const [activeMatchIndex, setActiveMatchIndex] = useState<number | null>(null);
  const [expandedPlayers, setExpandedPlayers] = useState<{
    [key: string]: boolean;
  }>({});
  const [itemsMap, setItemsMap] = useState<{ [key: number]: any }>({});
  const [hoveredItem, setHoveredItem] = useState<{
    gameId: number;
    position: { top: number; left: number };
  } | null>(null);
  const navigate = useNavigate();

  const toggleDropdown = (index: number) => {
    setActiveMatchIndex(activeMatchIndex === index ? null : index);
  };

  const togglePlayerCollapse = (playerId: string) => {
    setExpandedPlayers((prev) => ({
      ...prev,
      [playerId]: !prev[playerId],
    }));
  };

  useEffect(() => {
    // Load all items on component mount
    const loadAllItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/items`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const items = response.data;

        // Convert the items array to a map for fast lookup by game_id
        const itemsById = items.reduce(
          (acc: { [key: number]: any }, item: any) => {
            acc[item.game_id] = item;
            return acc;
          },
          {}
        );

        setItemsMap(itemsById);
      } catch (error) {
        console.error("Failed to load items:", error);
      }
    };

    loadAllItems();
  }, []);

  const handleMouseEnter = (game_id: number, event: React.MouseEvent) => {
    const { top, left, height } = (
      event.currentTarget as HTMLElement
    ).getBoundingClientRect();
    setHoveredItem({
      gameId: game_id,
      position: {
        top: top + window.scrollY - height + 30, // Position tooltip above the item with slight spacing
        left: left + window.scrollX + height / 2, // Center the tooltip horizontally
      },
    });
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handlePlayerClick = async (playerName: string) => {
    setGlobalLoading(true); // Show loading indicator while fetching data

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/player/search?username=${playerName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // After data is fetched, navigate to the new page with data in state
      navigate(`/profile/${playerName}`, {
        state: { profileData: response.data },
      });
    } catch (error) {
      console.error("Player search failed", error);
    } finally {
      setGlobalLoading(false); // Turn off loading indicator after navigation or error
    }
  };

  const filteredMatches = matches?.filter(
    (match) => match.game_mode !== "brawl"
  );

  const sortedMatches = filteredMatches?.sort(
    (a, b) =>
      new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
  );

  return (
    <div className="min-h-screen px-6 py-8">
      {/* Loading State with Spinner and Animated Message */}
      <div className="space-y-6">
        {sortedMatches?.map((match, index) => {
          const user = match.players.find(
            (player: any) => player.display_name === username
          );

          if (!user) {
            return null;
          }

          const isVictory = user.team === match.winning_team;
          const resultLabel = isVictory ? "Victory" : "Defeat";
          const resultColor = isVictory ? "bg-green-500" : "bg-red-500";
          const resultIcon = isVictory ? <FaTrophy /> : <FaSkull />;

          const teamDawn = match.players.filter(
            (player: any) => player.team === "dawn"
          );
          const teamDusk = match.players.filter(
            (player: any) => player.team === "dusk"
          );

          // Reorder the inventory: last 3 items first, then the rest for the user
          const reorderedInventory = user?.inventory_data
            ? user.inventory_data
                .slice(-3)
                .concat(user.inventory_data.slice(0, -3))
            : [];

          return (
            <div className="w-full max-w-7xl px-2 md:px-6 mx-auto">
              <div
                key={index}
                className={`card bg-gray-800 bg-opacity-60 transition rounded-2xl ease-in-out w-full ${
                  activeMatchIndex === index ? "scale-100" : "hover:scale-105"
                } ${
                  activeMatchIndex === index ? "bg-gray-800" : "bg-gray-900"
                }`}
                onClick={() => toggleDropdown(index)}
              >
                {/* Colored bar at the very top inside the card */}
                <div
                  className={`w-full h-2 rounded-t-3xl ${
                    isVictory ? "bg-green-500/70" : "bg-red-500/70"
                  }`}
                ></div>
                {/* Main Card Layout: Hero, KDA, Items, and Result */}
                <div
                  className="card-body shadow-lg p-4 w-full rounded-2xl ease-out cursor-pointer"
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                    boxShadow: "4px 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
                  }}
                >
                  <div className="flex flex-wrap justify-center gap-16 items-center">
                    <div className="flex flex-col items-center space-y-2 pr-4">
                      <p className="badge p-4 text-md font-bold text-gray-400">
                        {match.game_mode.toUpperCase()}
                      </p>
                    </div>
                    {/* Hero Icon and Name */}
                    <div className="flex flex-col items-center space-y-2 pr-4">
                      <img
                        src={`/heroes/${user.hero_id}.webp`}
                        alt={user.hero_id}
                        className="w-14 h-14 rounded-full border-4 shadow-md"
                      />
                      <p className="text-md text-gray-400">
                        {heroMap[user.hero_id] || "Unknown Hero"}
                      </p>
                    </div>

                    {/* KDA */}
                    <div className="flex flex-col items-center">
                      <p className="text-xl font-semibold whitespace-nowrap m-4">
                        <span className="text-green-600">{user.kills}</span> /{" "}
                        <span className="text-red-700">{user.deaths}</span> /{" "}
                        <span className="text-cyan-500">{user.assists}</span>
                      </p>
                      <p className="text-lg text-white whitespace-nowrap">
                        KDA:{" "}
                        {((user.kills + user.assists) / user.deaths).toFixed(2)}
                      </p>
                    </div>

                    {/* Victory/Defeat Status */}
                    <div className="flex flex-col items-center space-y-3 font-bold">
                      <p className="text-md">
                        {Math.floor(match.game_duration / 60)} min
                      </p>
                    </div>

                    {/* Render Tooltip only if there's a hovered item */}
                    {hoveredItem && itemsMap[hoveredItem.gameId] && (
                      <Tooltip
                        item={itemsMap[hoveredItem.gameId]}
                        position={hoveredItem.position}
                      />
                    )}

                    {/* Inventory Items (Always Visible on Main Card) */}
                    {/* Use grid layout for responsive design */}
                    <div className="flex items-center space-x-2">
                      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-2 md:ml-auto">
                        {reorderedInventory
                          .filter((_: any, i: any) => i === 1)
                          .map((item: number, i: number) => (
                            <div
                              key={i}
                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                              onMouseEnter={(e) => handleMouseEnter(item, e)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {item !== -1 ? (
                                <img
                                  src={`/items/${item}.webp`}
                                  alt={`Item ${item}`}
                                  className="w-full h-full object-cover rounded-full"
                                />
                              ) : (
                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>

                      <div className="grid grid-col-2 md:grid-row-2 gap-2 mt-2">
                        {reorderedInventory
                          .filter((_: any, i: any) => i === 0 || i === 2)
                          .map((item: number, i: number) => (
                            <div
                              key={i}
                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                              onMouseEnter={(e) => handleMouseEnter(item, e)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {item !== -1 ? (
                                <img
                                  src={`/items/${item}.webp`}
                                  alt={`Item ${item}`}
                                  className="w-full h-full object-cover rounded-full"
                                />
                              ) : (
                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>

                      <div className="grid grid-cols-3 md:grid-cols-3 mt-2 gap-2">
                        {reorderedInventory
                          .filter(
                            (_: any, i: any) => i !== 1 && i !== 0 && i !== 2
                          )
                          .map((item: number, i: number) => (
                            <div
                              key={i}
                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                              onMouseEnter={(e) => handleMouseEnter(item, e)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {item !== -1 ? (
                                <img
                                  src={`/items/${item}.webp`}
                                  alt={`Item ${item}`}
                                  className="w-full h-full object-cover rounded-full"
                                />
                              ) : (
                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {/* Expanded Player List */}
                  {activeMatchIndex === index && (
                    <div className="flex justify-center items-center">
                      <div
                        className="flex w-full max-w-4xl justify-center mt-4 p-6 bg-gradient-to-r from-gray-800/60 to-gray-900/60 rounded-3xl shadow-2xl border border-gray-700 overflow-auto"
                        onClick={(e) => e.stopPropagation()} // Prevents closing the main card on click
                      >
                        <div className="grid grid-row md:grid-cols-2 gap-24">
                          {/* Dawn Team */}
                          <div>
                            <h5 className="text-xl font-semibold text-white mb-4">
                              Dawn Team
                            </h5>
                            {teamDawn.map((player: any) => (
                              <div key={player.id} className="mb-4">
                                {/* Clickable player card */}
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    togglePlayerCollapse(player.id);
                                  }}
                                  className={`flex items-center space-x-3 cursor-pointer bg-gray-800 p-4 rounded-lg shadow-md transform hover:scale-105 transition duration-300 ease-out ${
                                    expandedPlayers[player.id]
                                      ? "bg-opacity-80"
                                      : "bg-opacity-60"
                                  }`}
                                >
                                  {/* Player's Hero Icon */}
                                  <img
                                    src={`/heroes/${player.hero_id}.webp`}
                                    alt={player.hero_id}
                                    className="w-14 h-14 rounded-full border-2 border-purple-500 shadow-lg"
                                  />
                                  <div className="text-left">
                                    {/* Player's Name */}
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handlePlayerClick(player.display_name);
                                      }}
                                      className="text-white font-semibold text-lg hover:text-purple-400 transition duration-300"
                                    >
                                      {player.display_name}
                                    </button>
                                    <p className="text-md text-gray-400">
                                      {player.role}
                                    </p>
                                  </div>
                                </div>

                                {/* Expanded Player Details (KDA & Inventory) */}
                                {expandedPlayers[player.id] && (
                                  <div className="mt-4 bg-gradient-to-br from-gray-800 to-gray-700 p-6 rounded-2xl shadow-lg transition duration-300 hover:shadow-xl">
                                    {/* Centered KDA Text */}
                                    <div className="flex justify-center mb-4">
                                      <p className="text-md text-gray-400 text-center">
                                        KDA: {player.kills}/{player.deaths}/
                                        {player.assists}
                                      </p>
                                    </div>

                                    {/* Add consistent spacing between grids */}
                                    <div className="flex items-center">
                                      {/* First item grid */}
                                      <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mr-2">
                                        {player.inventory_data
                                          ?.slice(7, 8)
                                          .map((item: number, i: any) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full object-cover rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>

                                      {/* Second item grid */}
                                      <div className="grid grid-row-2 gap-2 mr-2">
                                        {[
                                          player.inventory_data?.[6],
                                          player.inventory_data?.[8],
                                        ]
                                          .filter((item) => item !== undefined)
                                          .map((item: number, i: number) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>

                                      {/* Third item grid */}
                                      <div className="grid grid-cols-3 gap-2">
                                        {player.inventory_data
                                          ?.slice(0, 6)
                                          .map((item: number, i: any) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full object-cover rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>

                          {/* Repeat for Dusk Team */}
                          <div>
                            <h5 className="text-xl font-semibold text-white mb-4">
                              Dawn Team
                            </h5>
                            {teamDusk.map((player: any) => (
                              <div key={player.id} className="mb-4">
                                {/* Clickable player card */}
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    togglePlayerCollapse(player.id);
                                  }}
                                  className={`flex items-center space-x-3 cursor-pointer bg-gray-800 p-4 rounded-lg shadow-md transform hover:scale-105 transition duration-300 ease-out ${
                                    expandedPlayers[player.id]
                                      ? "bg-opacity-80"
                                      : "bg-opacity-60"
                                  }`}
                                >
                                  {/* Player's Hero Icon */}
                                  <img
                                    src={`/heroes/${player.hero_id}.webp`}
                                    alt={player.hero_id}
                                    className="w-14 h-14 rounded-full border-2 border-purple-500 shadow-lg"
                                  />
                                  <div className="text-left">
                                    {/* Player's Name */}
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handlePlayerClick(player.display_name);
                                      }}
                                      className="text-white font-semibold text-lg hover:text-purple-400 transition duration-300"
                                    >
                                      {player.display_name}
                                    </button>
                                    <p className="text-md text-gray-400">
                                      {player.role}
                                    </p>
                                  </div>
                                </div>

                                {/* Expanded Player Details (KDA & Inventory) */}
                                {expandedPlayers[player.id] && (
                                  <div className="mt-4 bg-gradient-to-br from-gray-800 to-gray-700 p-6 rounded-2xl shadow-lg transition duration-300 hover:shadow-xl">
                                    {/* Centered KDA Text */}
                                    <div className="flex justify-center mb-4">
                                      <p className="text-md text-gray-400 text-center">
                                        KDA: {player.kills}/{player.deaths}/
                                        {player.assists}
                                      </p>
                                    </div>

                                    {/* Add consistent spacing between grids */}
                                    <div className="flex items-center">
                                      {/* First item grid */}
                                      <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mr-2">
                                        {player.inventory_data
                                          ?.slice(7, 8)
                                          .map((item: number, i: any) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full object-cover rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>

                                      {/* Second item grid */}
                                      <div className="grid grid-row-2 gap-2 mr-2">
                                        {[
                                          player.inventory_data?.[6],
                                          player.inventory_data?.[8],
                                        ]
                                          .filter((item) => item !== undefined)
                                          .map((item: number, i: number) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>

                                      {/* Third item grid */}
                                      <div className="grid grid-cols-3 gap-2">
                                        {player.inventory_data
                                          ?.slice(0, 6)
                                          .map((item: number, i: any) => (
                                            <div
                                              key={i}
                                              className="relative w-10 h-10 rounded-full border border-purple-500 flex items-center justify-center bg-gray-800 hover:bg-purple-700 transition transform hover:scale-110"
                                              onMouseEnter={(e) =>
                                                handleMouseEnter(item, e)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              {item !== -1 ? (
                                                <img
                                                  src={`/items/${item}.webp`}
                                                  alt={`Item ${item}`}
                                                  className="w-full h-full object-cover rounded-full border border-slate-500"
                                                />
                                              ) : (
                                                <div className="w-full h-full border border-gray-700 rounded-full"></div>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentMatches;
