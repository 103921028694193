import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { FaCamera, FaTrophy, FaGamepad, FaHandsHelping } from "react-icons/fa";
import { MdOutlineLeaderboard } from "react-icons/md";
import { GiCrossedSwords, GiPodium, GiDeathSkull, GiSwordsEmblem, GiDeathZone, GiEyeTarget  } from "react-icons/gi"; // Game Icons
import useTeamStats from "../../hooks/useTeamStats";
import { useTeamDetails } from "../../hooks/useTeamDetails";
import { useTournaments } from "../../hooks/useTournaments";
import { toast } from "react-toastify";

interface TeamProfileProps {
  teamId: number;
  team: string;
  captain: string;
  isCaptain: boolean;
  teamLogo: string;
}

const TeamProfile: React.FC<TeamProfileProps> = ({
  teamId,
  team,
  captain,
  isCaptain,
  teamLogo,
}) => {
  const defaultLogoPath =
    "https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg";
  const [logo, setLogo] = useState<string>(teamLogo || defaultLogoPath);

  const { teamStats } = useTeamStats(teamId);
  const { data: teamDetails, refetch } = useTeamDetails(teamId);
  const { tournamentsQuery } = useTournaments(teamId);

  const tournaments = tournamentsQuery.data || [];

  // Handle file input change and upload
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}/upload-logo`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLogo(response.data.team_logo);
      await refetch();
      toast.success("Logo updated successfully!");
    } catch (error) {
      toast.error("Error uploading logo");
    }
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setLogo(response.data.teamLogo || defaultLogoPath);
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeamData();
  }, [teamId, defaultLogoPath]);

  return (
    <div className="flex flex-col justify-center items-center w-full mb-8">
      <div
        className="card shadow-lg w-full max-w-2xl mb-8 rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3"
        style={{
          background:
            "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
          boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
        }}
      >
        <h1 className="text-4xl font-bold text-white mb-4">{team}</h1>

        <div className="relative w-28 h-28">
          <img
            src={logo}
            alt={`${team} logo`}
            className="w-full h-full object-cover rounded-full shadow-lg border-2 border-primary ring-2 ring-offset-4 ring-offset-gray-900"
          />
          {isCaptain && (
            <>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                className="hidden"
                accept="image/*"
              />
              <div
                className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300"
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                <FaCamera className="text-white text-2xl" />
              </div>
            </>
          )}
        </div>

        <div className="mt-6 text-center">
          <p className="text-lg text-white flex items-center justify-center space-x-2">
            <span className="bg-gradient-to-r from-yellow-400 to-orange-500 text-xs text-black font-bold px-2 py-1 rounded-full">
              Captain
            </span>
            <span>{captain}</span>
          </p>

          <div className="flex items-center justify-center space-x-4 mt-8">
            {/* Wins Box */}
            <div
              className="card bg-gray-800/30 text-white font-bold min-w-[10rem] h-[5rem] flex flex-col justify-center items-center rounded-lg shadow-lg"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "2px 1px 2px 2px rgba(139, 92, 246, 0.4)", // soft purple outer glow
              }}
            >
              <span className="bg-gradient-to-r from-green-300 to-green-700 text-sm text-black font-bold px-2 py-1 rounded-full">
                Wins
              </span>
              <p className="text-2xl">{teamDetails?.wins}</p>
            </div>
            {/* Losses Box */}
            <div
              className="card bg-gray-800/40 text-white font-bold min-w-[10rem] h-[5rem] flex flex-col justify-center items-center rounded-lg shadow-lg"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "2px 1px 2px 2px rgba(139, 92, 246, 0.4)", // soft purple outer glow
              }}
            >
              <span className="bg-gradient-to-r from-red-300 to-red-700 text-sm text-black font-bold px-2 py-1 rounded-full">
                Losses
              </span>
              <p className="text-2xl">{teamDetails?.losses}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Team Statistics */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-4 w-full max-w-3xl px-6">
        {[
          {
            icon: <FaGamepad />,
            label: "Games Played",
            value: teamStats.gamesPlayed,
            color: "black",
          },
          {
            icon: <GiSwordsEmblem />,
            label: "Kills",
            value: teamStats.totalKills,
            color: "gray-500",
          },
          {
            icon: <GiDeathZone />,
            label: "Deaths",
            value: teamStats.totalDeaths,
            color: "red-500",
          },
          {
            icon: <FaHandsHelping />,
            label: "Assists",
            value: teamStats.totalAssists,
            color: "blue-500",
          },
          {
            icon: <GiEyeTarget />,
            label: "Wards",
            value: teamStats.totalWardsPlaced,
            color: "green-400",
          },
          {
            icon: <MdOutlineLeaderboard />,
            label: "Win/Loss Ratio",
            value: `${(teamStats.winLossRatio * 100).toFixed(2)}%`,
            color: "purple-500",
          },
        ].map((stat, idx) => (
          <motion.div
            key={idx}
            className="card shadow-lg rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3 hover:scale-105"
            style={{
              background:
                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
              boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            {stat.icon && (
              <div className={`text-${stat.color} text-3xl mb-2`}>
                {stat.icon}
              </div>
            )}
            <h3 className="text-md font-semibold text-gray-200">
              {stat.label}
            </h3>
            <p className="text-xl font-bold text-white">{stat.value}</p>
          </motion.div>
        ))}
      </div>
      {/* Registered Tournament Section */}
      <div className="mb-4 mt-8 text-center">
        <h2 className="text-2xl font-bold text-purple-500">
          Registered Tournaments
        </h2>
        <p className="text-gray-400">
          These are the tournaments your team is registered for.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 w-full max-w-2xl">
        {tournaments.map((tournament) => (
          <Link
            key={tournament.id}
            to={`/tournaments/${tournament.id}`}
            className="transform transition-all duration-300 ease-out hover:scale-105"
          >
            <motion.div
              className="card shadow-lg rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
              }}
            >
              <h3 className="text-lg font-semibold text-gray-200 flex items-center space-x-2">
                <FaTrophy className="text-yellow-400" /> {/* Trophy Icon */}
                <span>{tournament.name}</span> {/* Tournament Name */}
              </h3>
              <p className="text-md text-gray-400 mt-2 mb-2 text-center">
                {tournament.description}
              </p>
              <p className="text-sm text-gray-500">
                Start Date:{" "}
                {new Date(tournament.start_date).toLocaleDateString()}
              </p>
            </motion.div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TeamProfile;
