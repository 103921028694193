import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

interface BugReport {
  title: string;
  description: string;
}

const BugReportForm = () => {
  const [bugReport, setBugReport] = useState<BugReport>({
    title: "",
    description: "",
  });
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBugReport({
      ...bugReport,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", bugReport.title);
    formData.append("description", bugReport.description);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/bug-reports`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSuccessMessage("Bug report submitted successfully!");
      toast.success("Bug report submitted successfully!");
      setBugReport({ title: "", description: "" });
    } catch (error) {
      toast.error("Error submitting bug report");
      console.error("Error submitting bug report:", error);
    }
  };
  return (
    <div className="max-w-lg mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Report a Bug</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-control">
          <label htmlFor="title" className="label">
            <span className="label-text">Bug Title</span>
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={bugReport.title}
            onChange={handleChange}
            placeholder="Enter a brief title"
            className="input input-bordered"
            required
          />
        </div>
        <div className="form-control">
          <label htmlFor="description" className="label">
            <span className="label-text">Description</span>
          </label>
          <textarea
            id="description"
            name="description"
            value={bugReport.description}
            onChange={handleChange}
            placeholder="Describe the bug in detail"
            className="textarea textarea-bordered"
            rows={4}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary w-full">
          Submit Bug Report
        </button>
      </form>
    </div>
  );
};

export default BugReportForm;
