import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaTrophy, FaSkull, FaAssistiveListeningSystems } from "react-icons/fa";

interface TeamMatchesProps {
  matches: Array<any>;
  playerIDs: Array<string>; // Array of player IDs to identify the team
  teamPlayerIDs: Array<string>;
  team: string;
}

const TeamMatches: React.FC<TeamMatchesProps> = ({ matches, playerIDs, teamPlayerIDs, team }) => {
  const [opposingTeamName, setOpposingTeamName] = useState<any>(null);

  const sortedMatches = matches?.sort(
    (a, b) =>
      new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
  );

  const fetchPlayerDetails = async (
    displayName: string,
    setTeamData: Function
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/player/search?username=${displayName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTeamData(response.data);
    } catch (error) {
      console.error("Error fetching player details:", error);
    }
  };


  useEffect(() => {
    if (sortedMatches.length > 0) {
      sortedMatches.forEach((match) => {
        // Get players for the user's team and opposing team
        const teamPlayers = match.players.filter((player: any) =>
          teamPlayerIDs.includes(player.id)
        );
  
        const userTeam = teamPlayers[0]?.team; // Assuming all team players are on the same team
        const opposingTeam = userTeam === "dawn" ? "dusk" : "dawn";
  
  
        // Get all players from the opposing team
        const opposingTeamPlayers = match.players.filter(
          (player: any) => player.team === opposingTeam
        );
  
        // Fetch player details for the opposing team
        opposingTeamPlayers.forEach((player: any) => {
          fetchPlayerDetails(player.display_name, (data: any) => {
            if (opposingTeam) {
              setOpposingTeamName((prevData: any) => [...(prevData || []), data]);
            }
          });
        });
      });
    }
  }, [sortedMatches, teamPlayerIDs]);
  

  const renderMatchCard = (match: any) => {
    // Filter to get team and opposing players
    const teamPlayers = match.players.filter((player: any) =>
      teamPlayerIDs.includes(player.id)
    );

  
    // Get the team (dawn or dusk) for the user's team and the opposing team
    const userTeam = teamPlayers[0]?.team; // Assuming all team players are on the same team
    const opposingTeam = userTeam === 'dawn' ? 'dusk' : 'dawn'


    const roleOrder = ["offlane", "jungle", "midlane", "support", "carry"];

    const sortByRole = (players: Array<any>) => {
      return players.sort((a, b) => {
        return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role);
      });
    };

  
    // Dynamically assign Team 1 and Team 2
    const team1Players = match.players.filter(
      (player: any) => player.team === userTeam
    );
    const team2Players = match.players.filter(
      (player: any) => player.team === opposingTeam
    );

    const sortedTeam1Players = sortByRole(team1Players);
    const sortedTeam2Players = sortByRole(team2Players);

  
    // Determine if the user's team (Team 1) is the winning team
    const isVictory = match.winning_team === userTeam;
    const resultLabel = isVictory ? "Victory" : "Defeat";
    const resultColor = isVictory
      ? "bg-green-500 opacity-75"
      : "bg-red-700 opacity-75";
  
    return (
      <div
        className="card shadow-lg p-6 rounded-lg relative "
        style={{ backgroundColor: "rgba(31, 41, 55, 0.40)" }} // Dark gray with 40% opacity
      >
        {/* Top Bar for Result */}
        <div
          className={`absolute top-0 left-0 w-full ${resultColor} h-8 flex justify-center items-center rounded-t-lg`}
        >
          <span className="text-white font-bold text-sm">{resultLabel}</span>
        </div>
  
        {/* Team Display */}
        <div className="flex flex-col sm:flex-row justify-around items-center space-y-4 sm:space-y-0 sm:space-x-8 mt-4">
          {/* Team 1 (User's Team) */}
          <div className="text-center">
            <h4 className="text-md sm:text-lg font-semibold text-green-400 mb-2">
              {team}
            </h4>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-4">
              {sortedTeam1Players.map((player: any, idx: number) => (
                <div key={idx} className="flex flex-col items-center text-white">
                  <img
                    src={`/heroes/${player.hero_id}.webp`}
                    alt={player.display_name}
                    className="w-10 h-10 sm:w-12 sm:h-12 rounded-full mb-1 sm:mb-2"
                  />
                  <p className="text-xs">{player.display_name}</p>
                  <p className="text-xs">{player.role}</p>
                  <div className="flex space-x-1 sm:space-x-2 text-xs mt-1 sm:mt-2">
                    <span className="flex items-center">
                      <FaTrophy className="text-yellow-500 mr-1" /> {player.kills}
                    </span>
                    <span className="flex items-center">
                      <FaSkull className="text-red-500 mr-1" /> {player.deaths}
                    </span>
                    <span className="flex items-center">
                      <FaAssistiveListeningSystems className="text-blue-500 mr-1" /> {player.assists}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {/* VS Separator and Match Duration */}
          <div className="flex flex-col items-center">
            <div className="text-white text-lg sm:text-xl font-bold">VS</div>
            <div className="text-white text-sm sm:text-base mt-1">
              {Math.floor(match.game_duration / 60)} min
            </div>
          </div>
  
          {/* Team 2 (Opponent Team) */}
          <div className="text-center">
            <h4 className="text-md sm:text-lg font-semibold text-red-400 mb-2">
              {opposingTeamName?.team_name || "Opponent Team"}
            </h4>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-4">
              {sortedTeam2Players.map((player: any, idx: number) => (
                <div key={idx} className="flex flex-col items-center text-white">
                  <img
                    src={`/heroes/${player.hero_id}.webp`}
                    alt={player.display_name}
                    className="w-10 h-10 sm:w-12 sm:h-12 rounded-full mb-1 sm:mb-2"
                  />
                  <p className="text-xs">{player.display_name}</p>
                  <p className="text-xs">{player.role}</p>
                  <div className="flex space-x-1 sm:space-x-2 text-xs mt-1 sm:mt-2">
                    <span className="flex items-center">
                      <FaTrophy className="text-yellow-500 mr-1" /> {player.kills}
                    </span>
                    <span className="flex items-center">
                      <FaSkull className="text-red-500 mr-1" /> {player.deaths}
                    </span>
                    <span className="flex items-center">
                      <FaAssistiveListeningSystems className="text-blue-500 mr-1" /> {player.assists}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };  

  return (
    <div className="team-matches-section space-y-8 px-4">
      {(!matches || matches.length === 0) && (
        <div className="text-center text-white">
          No matches found for this team.
        </div>
      )}

      {sortedMatches.length > 0 && (
        <div>
          <div className="space-y-8">
            {sortedMatches.map((match: any, index: number) => (
              <div key={index}>{renderMatchCard(match)}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamMatches;