import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import ProfileDropdown from "../navbar/ProfileDropdown";
import { useProfile } from "../../hooks/useProfile";
import useNotifications from "../../hooks/useNotifications";
import { useWebSocket } from "../../context/WebSocketContext";
import NotificationItem from "../social/NotificationItem";
import logo from "../../images/logo.png";
import { XCircleIcon, BellIcon, Bars3Icon } from "@heroicons/react/20/solid";
import axios from "axios";

const Navbar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const {
    notifications,
    addNotification,
    removeNotification,
    toggleNotifications,
    showNotifications,
    newNotifications,
  } = useNotifications();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // WebSocket connection
  const { messages: socketMessages } = useWebSocket();
  const processedMessages = React.useRef<Set<string>>(new Set());

  const { data: profile } = useProfile(undefined);

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (socketMessages.length > 0) {
      const newMessages = socketMessages.filter(
        (msg: any) => !processedMessages.current.has(msg.id)
      );
      if (newMessages.length > 0) {
        newMessages.forEach((msg: any) => {
          addNotification(msg);
          processedMessages.current.add(msg.id);
        });
      }
    }
  }, [socketMessages, addNotification]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  const handleSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchTerm.length > 3) {
      e.preventDefault();
      setIsLoading(true); // Set loading to true when search begins
      try {
        // Perform both player and team search requests concurrently
        const [playerResponse, teamResponse] = await Promise.all([
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/api/players/search`, {
              params: { sq: searchTerm },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .catch((error) => {
              console.error("Player search failed:", error);
              return { data: [] }; // Return empty data if the player search fails
            }),
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/api/team/search`, {
              params: { teamName: searchTerm },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .catch((error) => {
              console.error("Team search failed:", error);
              return { data: [] }; // Return empty data if the team search fails
            }),
        ]);

        // Check if there are results in both responses
        const players = playerResponse.data;
        const teams = teamResponse.data;

        if (players.length > 0 && teams.length > 0) {
          // Navigate to a combined results page or show both results on the current page
          navigate("/player-teams", {
            state: { players, teams },
          });
        } else if (players.length > 0) {
          navigate("/players", { state: { players } });
        } else if (teams.length > 0) {
          navigate("/teams", { state: { teams } });
        } else {
          alert("No results found"); // Replace with a toast notification
        }

        setSearchTerm("");
      } catch (error) {
        console.error("Search failed:", error);
      } finally {
        setIsLoading(false); // Set loading to false when search completes
      }
    }
  };

  const handleClear = () => setSearchTerm("");

  return (
    <nav className="bg-gray-800 p-4 shadow-md">
      <div className="flex items-center justify-between max-w-7xl mx-auto">
        {/* Logo - Positioned to the Far Left */}
        <div className="flex justify-start items-start">
          <Link
            to="/welcome"
            className="text-white text-xl md:text-2xl font-bold flex items-center space-x-2"
          >
            <img src={logo} className="w-8 h-8 invert" alt="Logo" />
            <span>Prime Championship Circuit</span>
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="xl:hidden btn btn-square btn-ghost"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <Bars3Icon className="h-6 w-6 text-white" />
        </button>

        {/* Menu Links for Desktop */}
        <div className="hidden xl:flex space-x-6 items-center">
          {/* Development - DevBlog, BugReport */}
          <div className="dropdown dropdown-hover relative">
            <label tabIndex={0} className="btn btn-ghost text-white">
              Development
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-gray-800 rounded-box w-52 z-50"
            >
              <li>
                <Link
                  to="/patch-notes"
                  className="text-white hover:text-purple-400"
                >
                  Patch Notes
                </Link>
              </li>
              <li>
                <Link
                  to="/bug-reports"
                  className="text-white hover:text-purple-400"
                >
                  Bug Report
                </Link>
              </li>
            </ul>
          </div>

          {/* News, Leaderboard, Social Dropdown */}
          <div className="dropdown dropdown-hover relative">
            <label tabIndex={0} className="btn btn-ghost text-white">
              Community
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-gray-800 rounded-box w-52 z-50"
            >
              <li>
                <Link to="/news" className="text-white hover:text-purple-400">
                  News
                </Link>
              </li>
              <li>
                <Link
                  to="/leaderboard"
                  className="text-white hover:text-purple-400"
                >
                  Leaderboard
                </Link>
              </li>
              <li>
                <Link to="/social" className="text-white hover:text-purple-400">
                  Social
                </Link>
              </li>
            </ul>
          </div>

          {/* Create Match, Schedule Dropdown */}
          <div className="dropdown dropdown-hover relative">
            <label tabIndex={0} className="btn btn-ghost text-white">
              Compete
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-gray-800 rounded-box w-52 z-50"
            >
              <li>
                <Link
                  to="/team/create"
                  className="text-white hover:text-purple-400"
                >
                  Create Team
                </Link>
              </li>
              <li>
                <Link
                  to="/match/create"
                  className="text-white hover:text-purple-400"
                >
                  Create Match
                </Link>
              </li>
              <li>
                <Link
                  to="/draft/lobby"
                  className="text-white hover:text-purple-400"
                >
                  Create Draft
                </Link>
              </li>
              <li>
                <Link
                  to="/match/scheduled"
                  className="text-white hover:text-purple-400"
                >
                  Schedule
                </Link>
              </li>
              <li>
                <Link
                  to="/tournaments"
                  className="text-white hover:text-purple-400"
                >
                  Tournaments
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Search Bar for Desktop */}
        <div className="relative form-control w-full xl:w-72 hidden xl:block">
          <div className="flex items-center relative">
            <input
              type="text"
              placeholder="Search teams or players"
              className="input input-bordered w-full text-white bg-gray-700"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearch}
            />
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center">
              {isLoading ? (
                <div className="loader w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>

        {/* Notifications for Desktop */}
        <div className="relative hidden xl:block">
          <button
            className="btn btn-ghost text-white relative"
            onClick={toggleNotifications}
          >
            <BellIcon className="h-7 w-7" />
            {newNotifications && (
              <span className="badge badge-xs absolute top-0 right-0 bg-red-600 text-white">
                {notifications.length}
              </span>
            )}
          </button>
          {showNotifications && (
            <div className="absolute right-0 mt-2 bg-gray-800 w-64 border border-purple-500 shadow-lg rounded-lg z-50 overflow-auto max-h-72">
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    removeNotification={removeNotification}
                  />
                ))
              ) : (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No new notifications
                </div>
              )}
            </div>
          )}
        </div>

        {/* Profile Dropdown for Desktop */}
        <div className="hidden xl:block">
          {!profile ? (
            <div className="w-8 h-8 rounded-full bg-gray-600 animate-pulse" />
          ) : (
            <ProfileDropdown
              avatarUrl={profile?.avatarUrl || profile?.avatar_url}
            />
          )}
        </div>
      </div>

      {/* Off-Canvas Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 100 }}
            className="xl:hidden fixed top-0 right-0 w-3/4 h-full bg-gray-900 shadow-lg z-50"
          >
            <button
              className="absolute top-4 right-4 text-white"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <XCircleIcon className="h-6 w-6" />
            </button>
            <div className="flex flex-col items-center space-y-4 mt-16">
              <Link
                to="/news"
                className="text-white text-lg hover:text-purple-400"
              >
                News
              </Link>
              <Link
                to="/leaderboard"
                className="text-white text-lg hover:text-purple-400"
              >
                Leaderboard
              </Link>
              <Link
                to="/tournaments"
                className="text-white text-lg hover:text-purple-400"
              >
                Tournaments
              </Link>
              <Link
                to="/social"
                className="text-white text-lg hover:text-purple-400"
              >
                Social
              </Link>
              <Link
                to="/match/create"
                className="text-white text-lg hover:text-purple-400"
              >
                Create Match
              </Link>
              <Link
                to="/match/scheduled"
                className="text-white text-lg hover:text-purple-400"
              >
                Schedule
              </Link>

              {/* Mobile Profile Dropdown */}
              <ProfileDropdown avatarUrl={profile?.avatarUrl} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
