import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useProfile } from "../hooks/useProfile";
import { useTeamDetails } from "../hooks/useTeamDetails";
import { useTeamMatches } from "../hooks/useTeamMatches";
import TeamProfile from "../components/team_profile/TeamProfile";
import TeamMatches from "../components/team_profile/TeamMatches";
import TeamMembers from "../components/team_profile/TeamMembers";
import TeamMemberModal from "../components/team_profile/TeamMemberModal";
import PageWrapper from "../components/custom/PageWrapper";
import { toast } from "react-toastify";
import axios from "axios";
import UpcomingMatches from "../components/team_profile/UpcomingMatches";
import useScheduledMatches from "../hooks/useScheduledMatches";
import HashLoader from "react-spinners/HashLoader";

const TeamProfilePage: React.FC = () => {
  const { team_id } = useParams<{ team_id: string }>();
  const teamId = Number(team_id);

  // Hooks for fetching data
  const { data: profile, isLoading: profileLoading } = useProfile(undefined);
  const username = profile?.username;
  const { data: teamDetails, isLoading: isTeamDetailsLoading } = useTeamDetails(
    teamId || null
  );
  const { data: teamMatches, isLoading: isTeamMatchesLoading } =
    useTeamMatches(teamId);
  const { scheduledMatches, isLoading: loadingScheduledMatches } =
    useScheduledMatches(teamId);

  const [isModalOpen, setModalOpen] = useState(false);


  // Loading indicator that shows if any data is still loading
  const isLoading =
    profileLoading ||
    isTeamDetailsLoading ||
    isTeamMatchesLoading ||
    loadingScheduledMatches;

    const playerIDs = teamMatches?.flatMap((match: any) =>
      match.players.map((player: any) => player.id)
    );

    const teamPlayerIDs = teamDetails?.members?.map((m: any) => m.player_id)
    const teamName = teamDetails?.team

  useEffect(() => {
    // Scroll to the top of the page when this component mounts
    window.scrollTo(0, 0);
  }, [team_id]); // re-run effect when team_id changes

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleInvite = async (userId: number) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
        { team_id: teamId, invited_id: userId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      toast.success("Invitation sent");
    } catch (error) {
      toast.error("Error sending invite");
    }
  };

  return (
    <PageWrapper key={team_id}>
      <div className="min-h-screen px-6 py-8">
        {/* Show spinner if data is still loading */}
        {isLoading ? (
          <div className="flex flex-col justify-center items-center min-h-[75vh]">
            <HashLoader color="#6b46c1" size={75} />
            <div
              className="text-2xl font-bold mt-4"
              style={{
                animation:
                  "glow 2s ease-in-out infinite, pulse 2.5s ease-in-out infinite",
              }}
            >
              Summoning the spirit of Agora... one moment, hero!
            </div>
          </div>
        ) : (
          <>
            {/* Team Profile and Members Section */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="flex flex-col md:flex-row gap-8 mb-8"
            >
              {/* Team Profile Card */}
              <div className="card flex-1 bg-gray-700/20 shadow-2xl p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-purple-400 mb-4 text-start ">
                  Team Profile
                </h2>
                <TeamProfile
                  teamId={teamId}
                  team={teamDetails?.team}
                  captain={teamDetails?.captain}
                  isCaptain={username === teamDetails?.captain}
                  teamLogo={teamDetails?.teamLogo}
                />
              </div>

              {/* Team Members Card */}
              <div className="card flex-1 bg-gray-700/20 shadow-2xl p-6 rounded-lg">
                <TeamMembers
                  members={teamDetails?.members}
                  teamId={teamId!}
                  isCaptain={username === teamDetails?.captain}
                  openModal={openModal}
                />
              </div>
            </motion.div>

            {/* Upcoming Matches */}
            <div className="card flex-1 bg-gray-700/20 shadow-2xl p-6 rounded-lg">
              <h2 className="text-xl font-semibold text-purple-400 mb-4">
                Upcoming Matches
              </h2>
              <UpcomingMatches
                matches={scheduledMatches}
                loading={loadingScheduledMatches}
              />
            </div>

            {/* Previous Matches */}
            <div className="card flex-1 mt-6 bg-gray-700/20 shadow-2xl p-6 rounded-lg">
              <h2 className="text-xl font-semibold text-purple-400 mb-4">
                Previous Matches
              </h2>
              <TeamMatches matches={teamMatches} playerIDs={playerIDs} teamPlayerIDs={teamPlayerIDs} team={teamName}/>
            </div>

            {/* Team Member Modal */}
            <TeamMemberModal
              isOpen={isModalOpen}
              closeModal={closeModal}
              teamId={teamId!}
              handleInvite={handleInvite}
            />
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default TeamProfilePage;
