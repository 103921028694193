import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

interface Tournament {
  id: number;
  name: string;
  description: string;
  max_teams: number;
  currentTeamsCount?: number;
  start_date: string;
  teams?: Team[];
}

interface Team {
  id: number;
  name: string;
  team_logo: string;
  wins: number;
  losses: number;
  captain_id: number;
  players?: Player[]; // Make sure to add this line
}

interface Player {
  id: string;
  team_id: number;
  team_name: string;
  user_id: number;
  display_name: string;
  region: string;
  leaderboard_rank: number;
  top_percentage: number;
  vp_total: number;
  vp_current: number;
  is_active: boolean;
  rank: number;
  rank_title: string;
  rank_image: string;
  flags: any[];
}

interface CreateTournamentData {
  name: string;
  description: string;
  max_teams: number;
  start_date: string;
  end_date: string;
}

// Fetch all tournaments
const fetchTournaments = async (teamId?: number): Promise<Tournament[]> => {
  const url = teamId
    ? `${process.env.REACT_APP_BACKEND_URL}/api/tournaments?teamId=${teamId}`
    : `${process.env.REACT_APP_BACKEND_URL}/api/tournaments`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};

// Fetch specific tournament by ID
const fetchTournamentById = async (id: string): Promise<Tournament> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/tournaments/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

// Register a team for a specific tournament
const registerTeamForTournament = async ({
  tournamentId,
  teamId,
}: {
  tournamentId: number;
  teamId: number;
}): Promise<void> => {
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/tournaments/${tournamentId}/register`,
    { team_id: teamId },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

// Create a new tournament
const createTournament = async (
  data: CreateTournamentData
): Promise<Tournament> => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/tournaments/create`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data;
};

// Fetch relevant matches between two teams
const fetchRelevantMatches = async (
  team1_player_ids: string[],
  team2_player_ids: string[]
): Promise<any[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/match/tournament_matches`,
    {
      team1_player_ids,
      team2_player_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data.tournament_matches || [];
};

// Custom hook
export const useTournaments = (teamId?: number) => {
  const queryClient = useQueryClient();

  // Get all tournaments
  const tournamentsQuery = useQuery<Tournament[], Error>({
    queryKey: ["tournaments", teamId],
    queryFn: () => fetchTournaments(teamId),
  });

  // Get specific tournament by ID
  const useTournament = (id: string) =>
    useQuery<Tournament, Error>({
      queryKey: ["tournament", id],
      queryFn: () => fetchTournamentById(id),
      enabled: !!id,
    });

  // Register team for a tournament
  const registerTeamMutation = useMutation<
    void,
    Error,
    { tournamentId: number; teamId: number }
  >({
    mutationFn: registerTeamForTournament,
    onSuccess: () => {
      // Refetch tournaments data after successful registration
      queryClient.invalidateQueries({ queryKey: ["tournaments"] });
    },
  });

  // Create a tournament
  const createTournamentMutation = useMutation<
    Tournament,
    Error,
    CreateTournamentData
  >({
    mutationFn: createTournament,
    onSuccess: () => {
      // Refetch tournaments list after creating a new tournament
      queryClient.invalidateQueries({ queryKey: ["tournaments"] });
    },
  });

  return {
    tournamentsQuery,
    useTournament,
    registerTeamMutation,
    createTournamentMutation,
    fetchRelevantMatches,
  };
};
