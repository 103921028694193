import React, { useEffect, useState } from "react";
import { FaUserPlus, FaUserMinus, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useWebSocket } from "../../context/WebSocketContext"; // Import WebSocket context
import { useTeams } from "../../hooks/useTeams";
import { useNavigate } from "react-router-dom";

interface TeamMembersProps {
  members: Array<{
    id: number;
    username: string;
    avatar_url: string;
    user_id: number;
  }>;
  teamId: number | undefined;
  isCaptain: boolean;
  openModal: () => void;
}

const TeamMembers: React.FC<TeamMembersProps> = ({
  members: initialMembers,
  teamId,
  isCaptain,
  openModal,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [members, setMembers] = useState(initialMembers);
  const { messages } = useWebSocket(); // Listen to WebSocket messages
  const { deleteTeam } = useTeams();

  const navigate = useNavigate();

  // Fetch updated team members when the component mounts
  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMembers(response.data.members); // Update the team members list with fresh data
    } catch (error) {
      console.error("Error fetching team members", error);
    }
  };

  useEffect(() => {
    fetchTeamMembers(); // Fetch data when the component mounts or when the user navigates back to the page
  }, [teamId]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      fetchTeamMembers(); // Refetch team members when any relevant WebSocket message is received
    }
  }, [messages]);

  const handleDelete = async (teamId: number) => {
    try {
      await deleteTeam(teamId); // Call the delete function from useMutation
      setShowDeleteModal(false); // Close the modal
      navigate("/team/landing");
    } catch (error) {
      console.error("Failed to delete team:", error);
    }
  };

  const handleRemove = async (userId: number) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/remove`,
        { team_id: teamId, user_id: userId }, // Ensure correct user_id is passed
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMembers(members.filter((member) => member.user_id !== userId)); // Immediately update the state locally
      toast.success("Player removed");
    } catch (error) {
      toast.error("Error removing player");
      console.error("Error removing player", error);
    }
  };

  return (
    <div className="team-members-section mb-6 overflow-x-hidden">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold text-purple-400 ">Team Members</h2>
        {isCaptain && (
          <div className="flex justify-end items-center mb-6">
            {/* Action Buttons */}
            <div className="flex w-1/2 space-x-4 md:w-auto text-xs md:flex-row justify-end md:space-x-4 md:space-y-0 pr-2">
              {/* Delete Team Button */}
              <button
                className="btn btn-outline btn-error btn-sm flex items-center hover:bg-error hover:text-white transition-transform transform hover:scale-105 shadow-lg"
                onClick={() => setShowDeleteModal(true)}
              >
                <FaTrashAlt className="mr-2" /> Delete Team
              </button>

              {/* Add Member Button */}
              <button
                className="btn btn-outline btn-primary btn-sm flex items-center hover:bg-primary-focus transition-transform transform hover:scale-105 shadow-lg"
                onClick={openModal}
              >
                <FaUserPlus className="mr-2" /> Add Member
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-12 w-full mb-6 pl-6 pr-6">
        {members.map((member) => (
          <div
            onClick={() => navigate(`/profile/${member.username}`)}
            className="card shadow-lg rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3 cursor-pointer hover:scale-105"
            style={{
              background:
                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
              boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
            }}
          >
            <img
              src={
                member.avatar_url
                  ? member.avatar_url
                  : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
              }
              className="w-20 h-20 rounded-full mb-4 border-2 border-primary ring-2 ring-offset-4 ring-offset-gray-900 shadow-lg object-cover"
              alt={`${member.username}'s avatar`}
            />
            <p className="text-lg font-semibold text-white mb-2">
              {member.username}
            </p>
            {isCaptain && (
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation when remove button is clicked
                  handleRemove(member.user_id);
                }}
                className="btn btn-outline btn-error text-white btn-sm flex items-center mt-3 hover:scale-105 transition-transform"
              >
                <FaUserMinus className="mr-2" /> Remove
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal modal-open">
          <div className="modal-box bg-base-200">
            <h3 className="font-bold text-lg text-red-500">Confirm Deletion</h3>
            <p className="py-4 text-gray-300">
              Are you sure you want to delete this team? This action cannot be
              undone.
            </p>
            <div className="modal-action">
              <button
                onClick={() => {
                  handleDelete(teamId || 0);
                  setShowDeleteModal(false);
                }}
                className="btn btn-error hover:bg-error-focus"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamMembers;
