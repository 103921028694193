import React, { useEffect, useState } from "react";
import axios from "axios";
import { useProfile } from "../../hooks/useProfile";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

interface PatchNote {
  id: number;
  title: string;
  description: string;
  date: string;
}

const PatchNotes: React.FC = () => {
  const [patchNotes, setPatchNotes] = useState<PatchNote[]>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newPatchNote, setNewPatchNote] = useState<{
    title: string;
    description: string;
  }>({ title: "", description: "" });

  const { data: profile } = useProfile(undefined);

  // Render HTML content safely
  const renderHTMLContent = (html: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );

  useEffect(() => {
    // Fetch patch notes
    axios
      .get<PatchNote[]>(
        `${process.env.REACT_APP_BACKEND_URL}/api/patch-notes`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => setPatchNotes(response.data))
      .catch((error) => console.error("Error fetching patch notes:", error));
  }, []);

  console.log(patchNotes);

  const handleCreatePatchNote = (e: React.FormEvent) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/patch-notes`,
        newPatchNote,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setPatchNotes((prev) => [response.data.data, ...prev]); // Add new patch note to the list
        toast.success("Patch note created successfully!");
        setNewPatchNote({ title: "", description: "" }); // Reset form
        setShowForm(false); // Close form
      })
      .catch((error) => console.error("Error creating patch note:", error));
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Patch Notes</h1>

      {/* Admin Controls */}
      {profile?.role === "admin" && (
        <div className="mb-6 text-center">
          <button
            onClick={() => setShowForm(!showForm)}
            className="btn btn-primary"
          >
            {showForm ? "Cancel" : "Create Patch Note"}
          </button>
        </div>
      )}

      {/* Patch Note Creation Form */}
      {showForm && (
        <form
          onSubmit={handleCreatePatchNote}
          className="bg-base-200 p-4 rounded-lg shadow-md mb-6"
        >
          <div className="form-control">
            <label className="label">
              <span className="label-text">Title</span>
            </label>
            <input
              type="text"
              name="title"
              value={newPatchNote.title}
              onChange={(e) =>
                setNewPatchNote({ ...newPatchNote, title: e.target.value })
              }
              className="input input-bordered"
              placeholder="Enter the title of the patch note"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Description</span>
            </label>
            <ReactQuill
              theme="snow"
              value={newPatchNote.description}
              onChange={(content) =>
                setNewPatchNote({ ...newPatchNote, description: content })
              }
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              style={{
                height: "200px", // Set the height of the editor
                marginBottom: "40px",
              }}
            />
          </div>
          <div className="flex flex-col">
            <button type="submit" className="btn btn-success mt-4">
              Submit
            </button>
          </div>
        </form>
      )}

      {/* List of Patch Notes */}
      <div className="space-y-4">
        {patchNotes.map((note) => (
          <div
            key={note.id}
            className="card bg-base-100 border border-purple-500 shadow-md p-4 rounded-lg"
          >
            <h2 className="text-xl font-semibold">{note.title}</h2>
            <p className="text-sm text-gray-500">
              Released on: {new Date(note.date).toLocaleDateString()}
            </p>
            {/* Render description as HTML */}
            {renderHTMLContent(note.description)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatchNotes;
