import React from "react";
import Button from "../custom/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../hooks/useProfile";
import { useWebSocket } from "../../context/WebSocketContext";
import { format } from "date-fns";

interface NotificationItemProps {
  notification: {
    id: number;
    message: string;
    createdAt: string;
    sender_username: string;
    sender_avatar_url: string;
    receiver_username: string;
    receiver_avatar_url: string;
  };
  removeNotification: (id: number) => void;
  className?: string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  removeNotification,
  className,
}) => {
  const { id, message, createdAt } = notification;

  const { refetch } = useProfile(undefined); // Refetch profile when needed
  const { sendResponse } = useWebSocket();

  // Extract IDs from the message
  const extractIDs = (message: string, type: "match" | "team") => {
    if (type === "match") {
      const matchIDMatch = message.match(/\(Match ID: (\d+)\)/);
      return { matchID: matchIDMatch ? Number(matchIDMatch[1]) : null };
    } else if (type === "team") {
      const inviteIDMatch = message.match(
        /\(Invite ID: (\d+), Team ID: (\d+)\)/
      );
      return {
        inviteID: inviteIDMatch ? Number(inviteIDMatch[1]) : null,
        teamID: inviteIDMatch ? Number(inviteIDMatch[2]) : null,
      };
    }
    return {};
  };

  // Handle WebSocket responses
  const handleResponse = (type: "match" | "team", status: string) => {
    const ids = extractIDs(message, type);

    if (type === "match" && ids.matchID) {
      sendResponse({ type: "matchResponse", match_id: ids.matchID, status });
      toast.success(`Match invite ${status}`, { autoClose: 3000 });
    } else if (type === "team" && ids.inviteID && ids.teamID) {
      sendResponse({
        type: "teamResponse",
        invite_id: ids.inviteID,
        team_id: ids.teamID,
        status,
      });
      toast.success(`Team invite ${status}`, { autoClose: 3000 });

      if (status === "accepted") {
        refetch(); // Refetch profile if needed
      }
    }

    // Remove notification after handling
    removeNotification(id);
  };

  // Render HTML content safely
  const renderNotificationContent = (html: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );

  return (
    <div
      className={`${className} p-4 shadow-md rounded-md border border-gray-300`}
    >
      <li className="flex flex-col space-y-2">
        {/* Sender and Receiver Info */}
        <div className="flex justify-between items-center">
          {/* Sender Info */}
          <div className="flex items-center space-x-2">
            <img
              src={notification.sender_avatar_url}
              alt={`${notification.sender_username}'s avatar`}
              className="w-8 h-8 rounded-full"
            />
            <p className="font-semibold text-gray-400">
              {notification.sender_username}
            </p>
          </div>
        </div>

        {/* Notification Message */}
        <div className="mt-2">
          {renderNotificationContent(message)}
          <small className="text-gray-500 block mt-1 mb-2 text-xs">
            Received: {format(new Date(createdAt), "PPpp")}
          </small>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center space-x-2 mt-2">
          {extractIDs(message, "match").matchID && (
            <>
              <button
                className="btn btn-xs btn-success"
                onClick={() => handleResponse("match", "accepted")}
              >
                Accept
              </button>
              <button
                className="btn btn-xs btn-error"
                onClick={() => handleResponse("match", "declined")}
              >
                Decline
              </button>
            </>
          )}
          {extractIDs(message, "team").inviteID && (
            <>
              <Button
                variant="success"
                onClick={() => handleResponse("team", "accepted")}
              >
                Accept
              </Button>
              <Button
                variant="danger"
                onClick={() => handleResponse("team", "declined")}
              >
                Decline
              </Button>
            </>
          )}
        </div>

        {/* Dismiss Button */}
        <div className="flex justify-end">
          <button
            onClick={() => removeNotification(id)}
            className="text-red-500 hover:text-red-700 text-sm"
            title="Dismiss notification"
          >
            ✕
          </button>
        </div>
      </li>
    </div>
  );
};

export default NotificationItem;
