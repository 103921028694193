export const generateSwissPairings = (
  teams: any[],
  matchupCounts: { [key: string]: number }
): any[][] => {
  const pairings: any[][] = [];
  const usedTeams = new Set<number>();

  for (let i = 0; i < teams.length; i++) {
    if (usedTeams.has(teams[i].id)) continue;

    let paired = false;

    for (let j = i + 1; j < teams.length; j++) {
      const team1Id = teams[i].id;
      const team2Id = teams[j].id;
      const matchupKey = `${team1Id}-${team2Id}`;

      // Check if the teams have met fewer than the maximum allowed times
      if (!usedTeams.has(team2Id) && (matchupCounts[matchupKey] || 0) < 1) {
        // Track the pairing count
        matchupCounts[matchupKey] = (matchupCounts[matchupKey] || 0) + 1;
        matchupCounts[`${team2Id}-${team1Id}`] = matchupCounts[matchupKey];

        pairings.push([teams[i], teams[j]]);
        usedTeams.add(team1Id);
        usedTeams.add(team2Id);
        paired = true;
        break;
      } else {
        console.log(
          `Cannot pair ${teams[i].name} with ${teams[j].name} due to previous match count.`
        );
      }
    }

    // If no pairing was found for a team, assign it a bye
    if (!paired) {
      console.log(`No available pair for ${teams[i].name}, assigning a bye.`);
      pairings.push([teams[i], { id: 0, name: "Bye", players: [] }]);
      usedTeams.add(teams[i].id);
    }
  }

  return pairings;
};
