import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional: Tippy default styles
import logo from "../images/logo.png"; // Import the logo image

const RegisterPage: React.FC = () => {
  const [formData, setFormData] = useState({
    username: "",
    omeda_city_id: "",
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/register`,
        formData
      );
      setMessage(response.data.message);
    } catch (error: any) {
      setMessage(error.response?.data?.error || "Registration failed.");
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Background Image */}
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url('/PredAction2.jpg')`, // Replace with your actual image path
        }}
      />

      {/* Overlay */}
      <div className="absolute inset-0 w-full h-full bg-black opacity-80" />

      {/* Content */}
      <div className="z-10 w-full max-w-md p-8 rounded-lg">
        {/* Logo */}
        <img
          src={logo}
          alt="Logo"
          className="w-24 h-24 mx-auto mb-6 animate-slide-in invert"
        />

        {/* Title */}
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-8 text-center animate-slide-in">
          Create an Account
        </h1>

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            className="p-3 rounded bg-gray-800 text-white animate-slide-in"
            required
          />
          <div className="flex items-center gap-2">
            <input
              type="text"
              name="omeda_city_id"
              value={formData.omeda_city_id}
              onChange={handleChange}
              placeholder="Omeda City ID"
              className="p-3 rounded bg-gray-800 text-white flex-grow input-field animate-slide-in"
              required
            />
            <Tippy
              content={
                <div>
                  <p className="text-white text-sm mb-2">
                    Watch this video to learn how to find your Omeda City ID:
                  </p>
                  <video
                    controls
                    className="rounded shadow-lg"
                    width="500"
                    src="/videos/OmedaIDToolTip.mkv"
                  />
                </div>
              }
              placement="right"
              animation="scale"
              interactive={true}
            >
              <button
                type="button"
                className="text-white w-8 h-8 font-bold bg-blue-600 rounded-full hover:bg-blue-700 animate-slide-in flex items-center justify-center"
              >
                ?
              </button>
            </Tippy>
          </div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="p-3 rounded bg-gray-800 text-white animate-slide-in"
            required
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="p-3 rounded bg-gray-800 text-white animate-slide-in"
            required
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded hover:scale-105 duration-300 animate-slide-in"
          >
            Register
          </button>
          {message && <p className="mt-4 text-center">{message}</p>}
        </form>

        {/* Login Link */}
        <p className="mt-8 text-center text-white animate-slide-in">
          Already have an account?{" "}
          <Link to="/" className="text-blue-500 underline animate-slide-in">
            Login here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
