// Tooltip.tsx
import React from "react";
import ReactDOM from "react-dom";

interface TooltipProps {
  item: {
    name: string;
    game_id: number;
    display_name: string;
    total_price?: number;
    price?: number;
    stats?: Record<string, number>;
    effects?: Array<{ name: string; menu_description: string }>;
  };
  position: { top: number; left: number };
}

const Tooltip: React.FC<TooltipProps> = ({ item, position }) => {
  const tooltipContent = (
    <div
      className=" bg-gray-900 text-white p-4 rounded shadow-md w-96 z-50 flex flex-col space-y-4 pointer-events-none" // Vertical flex layout
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        transform: "translate(-50%, -100%)",
        whiteSpace: "normal",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
      }}
    >
      {/* Image Section - Centered at the top */}
      <div className="flex items-center justify-center">
        <img
          src={`/items/${item.game_id}.webp`}
          alt={`Item ${item.game_id}`}
          className="w-24 h-24 object-cover border rounded-full" // Adjusted size for better visibility
        />
      </div>

      {/* Content Section */}
      <div className="flex flex-col space-y-2">
        {/* Display Name */}
        <p className="text-lg font-bold text-purple-400 text-center">
          {item.display_name}
        </p>

        {/* Price Section */}
        {item.total_price !== undefined && (
          <div className="flex justify-between my-2">
            <span className="text-sm">Total Price:</span>
            <span className="text-sm font-bold">{item.total_price}</span>
          </div>
        )}

        {/* Stats Section */}
        <div className="text-sm mb-2">
          {item.stats && Object.keys(item.stats).length > 0 && (
            <>
              <p>Stats:</p>
              <ul className="grid grid-cols-2 gap-x-4 list-disc ml-4">
                {Object.entries(item.stats).map(([key, value]) => (
                  <li key={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        {/* Effects Section */}
        <div className="text-sm">
          {item.effects && item.effects.length > 0 && (
            <>
              <p>Effects:</p>
              <ul className="space-y-1">
                {item.effects.map((effect, index) => (
                  <li key={index}>
                    <p className="font-semibold text-green-400">
                      {effect.name}
                    </p>
                    <p className="whitespace-normal break-words">
                      {effect.menu_description}
                    </p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(tooltipContent, document.body);
};

export default Tooltip;
