import React, { useEffect, useState } from "react";
import axios from "axios";
import PageWrapper from "../components/custom/PageWrapper";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/HashLoader"; // Import HashLoader

type Player = {
  username: string;
};

type TeamData = {
  team: string;
  members: Player[];
};

type Match = {
  ID: string;
  team1_id: string;
  team2_id: string;
  match_time: string;
  status: string;
  team1_name?: string;
  team2_name?: string;
  team1_logo?: string;
  team2_logo?: string;
};

const ScheduledMatchesPage = () => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [selectedMatchId, setSelectedMatchId] = useState<string | null>(null);
  const [team1Data, setTeam1Data] = useState<TeamData | null>(null);
  const [team2Data, setTeam2Data] = useState<TeamData | null>(null);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchTeamData = async (teamId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching team data:", error);
      return null;
    }
  };

  const handleCardClick = async (match: Match) => {
    if (selectedMatchId === match.ID) {
      setSelectedMatchId(null);
    } else {
      const team1Data = await fetchTeamData(match.team1_id);
      const team2Data = await fetchTeamData(match.team2_id);

      setTeam1Data(team1Data);
      setTeam2Data(team2Data);
      setSelectedMatchId(match.ID);
    }
  };

  useEffect(() => {
    const fetchScheduledMatches = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/match/scheduled`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const matchesWithTeamData = await Promise.all(
          response.data.matches.map(async (match: Match) => {
            const team1Data = await fetchTeamData(match.team1_id);
            const team2Data = await fetchTeamData(match.team2_id);

            return {
              ...match,
              team1_name: team1Data?.team || "Unknown Team",
              team1_logo: team1Data?.teamLogo || "/pcc.jpeg",
              team2_name: team2Data?.team || "Unknown Team",
              team2_logo: team2Data?.teamLogo || "/pcc.jpeg",
            };
          })
        );
        setMatches(matchesWithTeamData);
      } catch (error) {
        console.error("Error fetching scheduled matches:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchScheduledMatches();
  }, []);

  return (
    <PageWrapper>
      <div className="flex flex-col justify-start min-h-screen">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-gradient-to-r from-purple-500 to-pink-600 text-white py-10 text-center rounded-lg mb-8 w-full"
        >
          <h2 className="text-4xl font-bold">Stay Ahead of the Game!</h2>
          <p className="mt-4 text-lg">
            View upcoming battles in the Prime Championship Circuit. Gear up,
            strategize, and conquer the leaderboard!
          </p>
        </motion.div>

        {/* Loader and Matches Display */}
        {loading ? (
          <div className="flex flex-col items-center">
            <HashLoader color="#6b46c1" size={60} />
            <p className="text-lg text-purple-500 mt-4">
              Fetching scheduled matches...
            </p>
          </div>
        ) : (
          <div className="flex flex-wrap justify-center">
            {matches.length > 0 ? (
              matches.map((match) => (
                <div
                  key={match.ID}
                  className={`card-container w-full max-w-lg m-4 shadow-xl rounded-lg hover:scale-105 transition-transform duration-300 animate-fade-in ${
                    selectedMatchId === match.ID ? "flipped" : ""
                  }`}
                  onClick={() => handleCardClick(match)}
                >
                  <div className="card-inner">
                    {/* Front of the card */}
                    <div className="card-front p-6 text-center border border-purple-500 rounded-lg">
                      <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
                        <div className="flex items-center space-x-4">
                          <img
                            src={match.team1_logo}
                            alt={match.team1_name}
                            className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 border-purple-500 object-cover"
                          />
                          <span className="text-lg font-semibold dark:text-white">
                            {match.team1_name}
                          </span>
                        </div>
                        <span className="text-xl sm:text-2xl font-extrabold text-purple-500 dark:text-gray-300">
                          VS
                        </span>
                        <div className="flex items-center space-x-4">
                          <span className="text-lg font-semibold dark:text-white">
                            {match.team2_name}
                          </span>
                          <img
                            src={match.team2_logo}
                            alt={match.team2_name}
                            className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 border-purple-500 object-cover"
                          />
                        </div>
                      </div>
                      <p className="dark:text-white text-sm sm:text-base mt-4 mb-4">
                        <strong>Match Time:</strong>{" "}
                        {new Date(match.match_time).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                          timeZone: "America/Chicago",
                          timeZoneName: "short",
                        })}
                      </p>
                      <span
                        className={`badge badge-outline px-4 py-1 rounded-full text-white font-semibold mt-4 ${
                          match.status === "pending"
                            ? "badge-warning"
                            : match.status === "declined"
                            ? "badge-error"
                            : "badge-success"
                        }`}
                      >
                        {match.status}
                      </span>
                    </div>

                    {/* Back of the card */}
                    <div className="card-back p-6 text-center border border-purple-500 rounded-lg">
                      <h3 className="text-xl font-bold text-purple-500 mb-4">
                        Team Members
                      </h3>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <h4 className="text-lg font-semibold text-green-400">
                            {team1Data?.team}
                          </h4>
                          <ul className="list-none space-y-1 mt-2">
                            {team1Data?.members.map((player, idx) => (
                              <li key={idx} className="text-sm text-white">
                                {player.username}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h4 className="text-lg font-semibold text-red-400">
                            {team2Data?.team}
                          </h4>
                          <ul className="list-none space-y-1 mt-2">
                            {team2Data?.members.map((player, idx) => (
                              <li key={idx} className="text-sm text-white">
                                {player.username}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center dark:text-white">
                No scheduled matches found.
              </p>
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ScheduledMatchesPage;
