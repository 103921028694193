// PlayerTeamResultsPage.tsx

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageWrapper from "../components/custom/PageWrapper";
import RankInfo from "../components/personal_profile/RankInfo";

const PlayerTeamResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { players = [], teams = [] } = location.state || {};

  const handlePlayerClick = (username: string) => {
    navigate(`/profile/${username}`);
  };

  const handleTeamClick = (teamId: string) => {
    navigate(`/team/${teamId}`);
  };

  return (
    <PageWrapper>
      <div className="p-8 max-w-screen-lg mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center text-gray-200">
          Player & Team Search Results
        </h2>

        {/* Players Section */}
        {players.length > 0 && (
          <div>
            <h3 className="text-3xl font-semibold mb-6 text-gray-300">
              Players
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 mb-8">
              {players.map((player: any) => (
                <div
                  key={player.id}
                  onClick={() => handlePlayerClick(player.display_name)}
                  className="card border border-purple-500 shadow-lg rounded-2xl hover:shadow-2xl transform hover:bg-primary/10 transition-colors duration-300 ease-out flex flex-col items-center p-3 cursor-pointer hover:scale-105"
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(31, 41, 55, 0.4))",
                    willChange: "transform",
                  }}
                >
                  <div className="flex-shrink-0">
                    <RankInfo
                      rank={player.rank_title}
                      vpTotal={player.vp_total}
                      size="large"
                    />
                  </div>

                  <div className="flex flex-col space-y-2 z-10">
                    <h4 className="text-2xl font-semibold text-white">
                      {player.display_name}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Teams Section */}
        {teams.length > 0 && (
          <div>
            <h3 className="text-3xl font-semibold mb-6 text-gray-300">Teams</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
              {teams.map((team: any) => (
                <div
                  key={team.id}
                  onClick={() => handleTeamClick(team.id)}
                  className="card border border-purple-500 shadow-lg rounded-2xl hover:shadow-xl transform hover:bg-primary/10 transition-colors duration-300 ease-out flex flex-col items-center p-4 cursor-pointer hover:scale-105"
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(31, 41, 55, 0.4))",
                    willChange: "transform",
                  }}
                >
                  <img
                    src={
                      team.team_logo
                        ? team.team_logo
                        : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
                    }
                    alt={`${team.name} logo`}
                    className="w-16 h-16 object-cover rounded-full shadow-lg mt-4 border-2 border-purple-500"
                  />

                  <div className="flex flex-col items-center space-y-2 z-10 mt-4">
                    <h4 className="text-2xl font-semibold text-white">
                      {team.name}
                    </h4>
                    <p className="text-lg text-gray-400">Team ID: {team.id}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* No Results Message */}
        {players.length === 0 && teams.length === 0 && (
          <p className="text-gray-500 text-center text-xl">No results found</p>
        )}
      </div>
    </PageWrapper>
  );
};

export default PlayerTeamResultsPage;
