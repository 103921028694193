// Utility function to shuffle an array
export const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


  export const getDraftSequence = (draftState: any, heroes: any) => {
    const sequence: any = [];
    const draftOrder = [
      { team: draftState.team1_name, type: "ban", list: draftState.team1_bans },
      { team: draftState.team2_name, type: "ban", list: draftState.team2_bans },
      { team: draftState.team1_name, type: "pick", list: draftState.team1_picks },
      { team: draftState.team2_name, type: "pick", list: draftState.team2_picks },
      { team: draftState.team2_name, type: "pick", list: draftState.team2_picks },
      { team: draftState.team1_name, type: "pick", list: draftState.team1_picks },
      { team: draftState.team1_name, type: "pick", list: draftState.team1_picks },
      { team: draftState.team2_name, type: "pick", list: draftState.team2_picks },
      { team: draftState.team2_name, type: "ban", list: draftState.team2_bans },
      { team: draftState.team1_name, type: "ban", list: draftState.team1_bans },
      { team: draftState.team2_name, type: "pick", list: draftState.team2_picks },
      { team: draftState.team1_name, type: "pick", list: draftState.team1_picks },
      { team: draftState.team1_name, type: "pick", list: draftState.team1_picks },
      { team: draftState.team2_name, type: "pick", list: draftState.team2_picks },
    ];
    
  
    let pickIndex = { team1: 0, team2: 0 };
    let banIndex = { team1: 0, team2: 0 };
  
    draftOrder.forEach((step) => {
      step.list.forEach((hero: any, index: any) => {
        const heroDetails = heroes.find((h: any) => h.name === hero) || {
          name: "Unknown",
          image: "/path/to/default.jpg",
        };
        sequence.push({
          team: step.team,
          type: step.type,
          hero: heroDetails,
          index: index + 1,
        });
      });
    });
  
    return sequence;
  };
