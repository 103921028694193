import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../hooks/useProfile";
import PageWrapper from "../components/custom/PageWrapper";
import { motion } from "framer-motion";
import { FaUserPlus, FaUserMinus } from "react-icons/fa";
import { toast } from "react-toastify"; // For toast notifications
import TeamMemberModal from "../components/team_profile/TeamMemberModal"; // Import TeamMemberModal
import { FaUpload } from "react-icons/fa";

interface User {
  id: number;
  username: string;
  avatar_url: string;
}

const CreateTeamPage: React.FC = () => {
  const [teamName, setTeamName] = useState("");
  const [members, setMembers] = useState<User[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const { refetch } = useProfile(undefined);
  const navigate = useNavigate();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleAddMember = (user: User) => {
    if (!members.find((member) => member.id === user.id)) {
      setMembers([...members, user]); // Add the selected user to the team
    }
    setIsModalOpen(false); // Close modal after selecting
  };

  const handleRemoveMember = (index: number) => {
    const updatedMembers = members.filter((_, i) => i !== index);
    setMembers(updatedMembers); // Remove a member
  };

  // Function to send team invite to a user
  const sendTeamInvite = async (teamId: number, userId: number) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
        { team_id: teamId, invited_id: userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success(`Invitation sent to ${userId}`);
    } catch (error) {
      toast.error(`Error sending invite to ${userId}`);
      console.error("Error sending invite", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append("name", teamName);
    formData.append("members", JSON.stringify(members)); // Add members as a JSON string
    if (selectedFile) {
      formData.append("logo", selectedFile);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { team_id, logo_url } = response.data; // Get team_id and logo_url from response

      // Send invites to all added members
      await Promise.all(
        members.map((member) => sendTeamInvite(team_id, member.id))
      );

      // Refetch the profile to ensure teamId is updated in the app
      await refetch();

      // Navigate to the team profile and refresh data to reflect the logo change
      navigate(`/team/${team_id}`, { state: { logo_url } });
    } catch (error) {
      if ((error as any).response) {
        const errorCode = (error as any).response.data.error;

        if (errorCode === "already_on_team") {
          toast.error(
            "You are already a member of a team and cannot create another one."
          );
        } else if (errorCode === "explicit_content") {
          toast.error("The uploaded logo contains inappropriate content.");
        } else if (errorCode === "scan_img_err") {
          toast.error(
            "Please try a different logo. Format should be .jpg or .png"
          );
        } else {
          toast.error("Error creating team");
        }
      } else {
        toast.error("Unexpected error occurred");
      }
    } finally {
      setIsLoading(false); // End loading after team creation
    }
  };

  return (
    <PageWrapper>
      <div className="flex flex-col justify-center items-center ">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-gradient-to-r from-purple-500 to-pink-600 text-white py-10 text-center rounded-lg mb-8 w-full"
        >
          <h2 className="text-4xl font-bold">Create Your Team and Compete!</h2>
          <p className="mt-4 text-lg">
            Be part of the Prime Championship Circuit. Build your squad,
            dominate the competition, and rise to the top!
          </p>
        </motion.div>

        {/* Form Section */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="bg-slate-900 bg-white/5 backdrop-blur-lg shadow-lg rounded-lg p-8 w-full max-w-lg border border-purple-500"
        >
          <h1 className="text-2xl font-bold mb-6 text-center">
            Create Your Team
          </h1>

          {isLoading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center"
            >
              Loading...
            </motion.div>
          ) : (
            <motion.form
              onSubmit={handleSubmit}
              className="space-y-6"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              {/* Team Logo Upload */}
              <div className="flex flex-col items-center">
                <label className="text-winter-white font-medium mb-2">
                  Team Logo
                </label>
                <motion.div
                  className="w-24 h-24 rounded-full ring-4 ring-purple-500 cursor-pointer overflow-hidden bg-gray-300 relative flex items-center justify-center"
                  onClick={() => document.getElementById("fileInput")?.click()}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {logoPreview ? (
                    <motion.img
                      src={logoPreview}
                      alt="Team Logo Preview"
                      className="object-cover w-full h-full"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    />
                  ) : (
                    <span className="text-gray-600">Upload</span>
                  )}

                  {/* Upload Icon Overlay on Hover */}
                  <motion.div
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FaUpload className="text-white text-2xl" />
                  </motion.div>
                </motion.div>
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  className="hidden"
                />
              </div>

              {/* Team Name Input */}
              <div className="flex flex-col">
                <label className="text-winter-white font-medium">
                  Team Name
                </label>
                <motion.input
                  type="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  className="mt-2 p-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter team name"
                  whileFocus={{ scale: 1.02 }}
                />
              </div>

              {/* Team Members Section */}
              <div className="flex flex-col">
                <label className="text-winter-white font-medium">
                  Team Members
                </label>
                {members.map((member, index) => (
                  <div key={index} className="flex items-center space-x-4 mt-2">
                    <motion.input
                      type="text"
                      value={member.username}
                      className="p-2 border rounded-lg w-full"
                      readOnly
                    />
                    <button
                      type="button"
                      className="btn btn-outline btn-primary hover:scale-105 hover:shadow-glow"
                      onClick={() => handleRemoveMember(index)}
                    >
                      <FaUserMinus className="text-sm" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="mt-4 btn btn-outline btn-primary hover:scale-105 hover:shadow-glow"
                  onClick={() => setIsModalOpen(true)} // Open TeamMemberModal
                >
                  <FaUserPlus className="mr-2" /> Add Members
                </button>
              </div>

              <motion.button
                type="submit"
                className="w-full p-3 btn btn-primary hover:scale-105 hover:shadow-glow"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Create Team
              </motion.button>
            </motion.form>
          )}
        </motion.div>

        {/* Modal for Adding Team Members */}
        <TeamMemberModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          teamId={0} // TeamId will be assigned after creation
          handleInvite={(userId: any) => {
            handleAddMember({ id: userId, username: "", avatar_url: "" });
          }}
        />
      </div>
    </PageWrapper>
  );
};

export default CreateTeamPage;
