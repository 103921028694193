import platinumImage from "../images/platinum.png";
import goldImage from "../images/gold.png";
import diamondImage from "../images/diamond.png";
import silverImage from "../images/silver.png";
import bronzeImage from "../images/bronze-unranked.png";
import paragonImage from "../images/paragon.png";
import unrankedImage from "../images/bronze-unranked.png";

// Utility function to map rank titles to image imports
const rankImageMap: { [key: string]: string } = {
  "Platinum I": platinumImage,
  "Platinum II": platinumImage,
  "Platinum III": platinumImage,
  "Gold I": goldImage,
  "Gold II": goldImage,
  "Gold III": goldImage,
  "Diamond I": diamondImage,
  "Diamond II": diamondImage,
  "Diamond III": diamondImage,
  "Silver I": silverImage,
  "Silver II": silverImage,
  "Silver III": silverImage,
  "Bronze I": bronzeImage,
  "Bronze II": bronzeImage,
  "Bronze III": bronzeImage,
  Paragon: paragonImage,
  Unranked: unrankedImage, // Default rank image
};

export const getRankImage = (rank: string | undefined): string => {
  return rank ? rankImageMap[rank] || unrankedImage : unrankedImage;
};
