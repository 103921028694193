import React from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { useProfile } from "../../hooks/useProfile";
import { useQueryClient } from "@tanstack/react-query";

interface ProfileDropdownProps {
  avatarUrl?: string;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ avatarUrl }) => {
  const { data: profile } = useProfile(undefined);
  const teamId = profile?.teamId;
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem("notifications");
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    localStorage.removeItem("refresh_token");
    queryClient.clear(); // Clear all cached query data
  };

  return (
    <div className="dropdown dropdown-hover dropdown-end">
      <label
        tabIndex={0}
        className="btn btn-ghost flex items-center space-x-2 text-white"
      >
        {avatarUrl ? (
          <img
            src={avatarUrl}
            alt="Profile Icon"
            className="w-8 h-8 rounded-full"
          />
        ) : (
          <FaUserCircle className="text-2xl" />
        )}
        <span className="hidden sm:block">{profile?.username}</span>
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu p-2 shadow bg-gray-800 rounded-box w-52 z-50"
      >
        <li>
          <Link to="/profile" className="text-white hover:text-purple-400">
            Profile
          </Link>
        </li>
        <li>
          <Link
            to={teamId ? `/team/${teamId}` : "/team/landing"}
            className="text-white hover:text-purple-400"
          >
            Team
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={handleLogout}
            className="text-white hover:text-purple-400"
          >
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
