import { useState, useCallback, useMemo } from "react";

interface Notification {
  id: number;
  message: string;
  seen: boolean; // Add a `seen` property to track visibility
  createdAt: string;
  sender_username: string,
  sender_avatar_url: string,
  receiver_username: string,
  receiver_avatar_url: string,
}

const useNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);

  // Derived state for new/unseen notifications
  const newNotifications = useMemo(
    () => notifications.some((notification) => !notification.seen),
    [notifications]
  );

  // Add a new notification with deduplication
  const addNotification = useCallback((notification: Notification) => {
    setNotifications((prevNotifications) => {
      const isDuplicate = prevNotifications.some(
        (n) => n.id === notification.id
      );
      return isDuplicate
        ? prevNotifications
        : [...prevNotifications, { ...notification, seen: false }];
    });
  }, []);

  // Remove a notification by ID
  const removeNotification = useCallback((id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  // Mark all notifications as "seen" when the dropdown is opened
  const markNotificationsAsSeen = useCallback(() => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({ ...notification, seen: true }))
    );
  }, []);

  // Toggle notifications dropdown visibility
  const toggleNotifications = useCallback(() => {
    setShowNotifications((prev) => !prev);
    if (!showNotifications) {
      markNotificationsAsSeen(); // Mark notifications as seen when opening
    }
  }, [showNotifications, markNotificationsAsSeen]);

  return {
    notifications,
    showNotifications,
    newNotifications,
    addNotification,
    removeNotification,
    toggleNotifications,
  };
};

export default useNotifications;
