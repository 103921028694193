import { Link } from "react-router-dom";
import PageWrapper from "../components/custom/PageWrapper";
import { motion } from "framer-motion"; // For animation

const TeamLandingPage = () => {
  return (
    <PageWrapper>
      {/* Hero Section */}
      <div className="min-h-screen text-white flex flex-col items-center justify-center relative overflow-hidden">
        {/* Background visual elements */}
        <div className="absolute inset-0 bg-hero-pattern opacity-40"></div>

        <div className="text-center z-10">
          {/* Main Heading */}
          <motion.h1
            className="text-5xl font-extrabold mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Ready to Compete?
          </motion.h1>

          {/* Subheading */}
          <motion.p
            className="text-lg mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Join the competitive scene by creating your own team and start your
            journey!
          </motion.p>

          {/* Call to Action Button */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            <Link
              to="/team/create"
              className="inline-block px-8 py-4 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-indigo-700 transition duration-300"
            >
              Create a Team
            </Link>
          </motion.div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default TeamLandingPage;
