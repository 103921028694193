import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RankInfo from "../components/personal_profile/RankInfo";
import PageWrapper from "../components/custom/PageWrapper";

const PlayerResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const players = location.state?.players || [];

  const handlePlayerClick = (username: string) => {
    navigate(`/profile/${username}`);
  };

  return (
    <PageWrapper>
      <div className="p-8 max-w-screen-lg mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center text-gray-200">
          Player Search Results
        </h2>
        {players.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            {players.map((player: any) => (
              <div
                onClick={() => handlePlayerClick(player.display_name)}
                className="card border border-purple-500 shadow-lg rounded-2xl hover:shadow-2xl transform hover:bg-primary/10 transition-colors duration-300 ease-out flex flex-col items-center p-3 cursor-pointer hover:scale-105"
                style={{
                  background:
                    "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(31, 41, 55, 0.4))",
                  willChange: "transform", // improves animation performance
                }}
              >
                <div className="flex-shrink-0">
                  {/* Use RankInfo component with medium size for better visibility */}
                  <RankInfo
                    rank={player.rank_title}
                    vpTotal={player.vp_total}
                    size="large"
                  />
                </div>

                <div className="flex flex-col space-y-2 z-10">
                  <h4 className="text-2xl font-semibold text-white">
                    {player.display_name}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center text-xl">No players found</p>
        )}
      </div>
    </PageWrapper>
  );
};

export default PlayerResultsPage;
