import React from "react";
import { motion } from "framer-motion";
import PageWrapper from "../custom/PageWrapper";

const HeroSection: React.FC = () => {
  return (
    <motion.div
      className="min-h-screen relative flex flex-col items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Background Logo */}
      <div
        className="absolute inset-0 bg-no-repeat bg-center opacity-10"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/pcc.jpeg)`,
          backgroundSize: "75%",
          backgroundPosition: "center center",
        }}
      />

      {/* Hero Content */}
      <motion.div
        className="relative text-center"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-extrabold drop-shadow-lg">
          Prime Championship Circuit
        </h1>
        <p className="mt-4 text-base sm:text-lg md:text-xl text-white">
          Compete, Connect, and Crush the Competition!
        </p>

        {/* Call to Action Button */}
        <motion.div
          className="mt-8 flex justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <a
            href="/get-started"
            className="btn bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 sm:px-8 sm:py-4 rounded-full shadow-lg hover:shadow-2xl transition-all duration-200 hover:from-pink-500 hover:to-purple-500"
          >
            Get Started
          </a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default HeroSection;
