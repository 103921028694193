import React, { ReactNode } from "react";

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-bg-dark-gradient bg-animate-gradient dark:bg-darkBg dark:text-darkText">
      {children}
    </div>
  );
};

export default PageWrapper;
