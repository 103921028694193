import React, { useState } from "react";
import { useNews } from "../../hooks/useNews";
import PageWrapper from "../custom/PageWrapper";

// Define the News item structure
interface NewItems {
  id: number;
  title: string;
  content: string;
  created_at: string;
  image_urls: string[];
}

// Function to remove markdown symbols like "*"
const sanitizeContent = (content: string) => {
  return content.replace(/[*_~`]/g, "");
};

const NewsFeed: React.FC = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Set lower itemsPerPage by default
  const token = localStorage.getItem("token");

  const { data: news = [], isLoading } = useNews(page, itemsPerPage, token);

  const handleNextPage = () => setPage((prev) => prev + 1);
  const handlePrevPage = () => setPage((prev) => Math.max(prev - 1, 1));

  return (
    <PageWrapper>
      <div className="news-feed min-h-screen text-base-content p-4">
        <div className="max-w-full lg:max-w-7xl mx-auto flex flex-col min-h-full">
          {/* Header Section */}
          <header className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold mb-2 text-primary dark:text-gray-200">
              Latest News
            </h2>
            <p className="text-neutral dark:text-gray-300">
              Stay up-to-date with the latest PCC news and announcements
            </p>
          </header>

          {/* Loading Indicator */}
          {isLoading && (
            <div className="text-center mb-4">
              <progress className="progress w-56"></progress>
            </div>
          )}

          {/* News Items in Card Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
            {news.map((item: NewItems) => (
              <div
                key={item.id}
                className="card bg-gray-800 bg-opacity-60 shadow-lg rounded-lg overflow-hidden border-2 border-purple-500 hover:scale-105"
              >
                {/* Media Section */}
                {item.image_urls && item.image_urls.length > 0 && (
                  <div className="flex overflow-hidden">
                    {item.image_urls.map((url, index) =>
                      url.endsWith(".mp4") ? (
                        <video
                          key={index}
                          src={url}
                          controls
                          className="w-full h-[450px] object-fill rounded-t-lg bg-black"
                        />
                      ) : (
                        <img
                          key={index}
                          src={url}
                          alt={`Attachment ${index + 1}`}
                          className="w-full h-[450px] object-fill rounded-t-lg bg-black"
                        />
                      )
                    )}
                  </div>
                )}

                {item.image_urls && item.image_urls.length > 0 && (
                  <div className="divider my-4 mx-6 opacity-50"></div>
                )}

                {/* Card Content */}
                <div className="card-body p-4 lg:p-6">
                  <p className="text-gray-700 dark:text-gray-300 mb-4 leading-relaxed">
                    {sanitizeContent(item.content)}
                  </p>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {new Date(item.created_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination and Items Per Page Selector */}
          <div className="pagination mt-auto flex justify-between items-center">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className={`btn ${
                page === 1
                  ? "btn-disabled"
                  : "btn-outline btn-primary hover:scale-105 hover:shadow-lg"
              }`}
            >
              Previous
            </button>
            <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-300">
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="select select-bordered"
              >
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
              </select>
            </div>
            <button
              onClick={handleNextPage}
              className="btn btn-outline btn-primary hover:scale-105 hover:shadow-lg"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default NewsFeed;
