import { useEffect, useState } from "react";
import axios from "axios";

interface TeamData {
  teamLogo?: string;
  team?: string;
}

interface Match {
  ID: number;
  team1_id: number;
  team2_id: number;
  match_time: string;
  team1Data?: TeamData;
  team2Data?: TeamData;
}

const useScheduledMatches = (teamId: number) => {
  const [scheduledMatches, setScheduledMatches] = useState<Match[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const fetchTeamScheduledMatches = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/match/scheduled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const teamScheduledMatches = response.data.matches.filter(
        (match: any) => match.team1_id === teamId || match.team2_id === teamId
      );

      // Fetch team1 and team2 data for each match
      const matchesWithTeamData = await Promise.all(
        teamScheduledMatches.map(async (match: Match) => {
          const [team1Response, team2Response] = await Promise.all([
            axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/team/${match.team1_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            ),
            axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/team/${match.team2_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            ),
          ]);

          return {
            ...match,
            team1Data: team1Response.data,
            team2Data: team2Response.data,
          };
        })
      );

      setScheduledMatches(matchesWithTeamData);
    } catch (error) {
      console.error("Error fetching scheduled matches:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchTeamScheduledMatches();
  }, [teamId]);

  return { scheduledMatches, isLoading }; // Return both matches and loading status
};

export default useScheduledMatches;
