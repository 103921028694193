import React from "react";

interface LoginButtonProps {
  platformName: string;
  onClick: () => void;
  Icon: React.ElementType; // Icon component (e.g., FaSteam, FaXbox)
  color: string;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  platformName,
  onClick,
  Icon,
  color,
}) => {
  return (
    <button
      onClick={onClick}
      className={`text-white font-bold w-full py-3 px-6 rounded-lg flex items-center justify-center shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 animate-slide-in ${color}`}
    >
      <Icon className="mr-2 text-xl" />
      Login with {platformName}
    </button>
  );
};

export default LoginButton;
