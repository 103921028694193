import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  ListboxButton,
  Transition,
} from "@headlessui/react";
import React, { useState, Fragment } from "react";
import { useTeams } from "../hooks/useTeams";
import { useProfile } from "../hooks/useProfile"; // Import the useProfile hook
import { useMutation } from "@tanstack/react-query";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import PageWrapper from "../components/custom/PageWrapper";
import { CalendarIcon, UsersIcon } from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

interface CreateMatchArgs {
  team1_id: number;
  team2_id: number;
  match_time: string;
}

const CreateMatchPage: React.FC = () => {
  // Get the user's profile, including their teamId
  const { data: profile, isLoading: profileLoading } = useProfile(undefined);

  // Extract the user's team ID from the profile
  const userTeamId = profile?.teamId || null;

  const [team2, setTeam2] = useState<number | null>(null); // Store team ID for team 2
  const [matchTime, setMatchTime] = useState<Date | null>(null);

  const {
    data: teams,
    isLoading: teamsLoading,
    isError: teamsError,
  } = useTeams();

  const mutation = useMutation({
    mutationFn: async ({ team1_id, team2_id, match_time }: CreateMatchArgs) => {
      const formattedMatchTime =
        match_time.length === 16 ? `${match_time}:00Z` : `${match_time}Z`;
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/match/create`,
        { team1_id, team2_id, match_time: formattedMatchTime },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    },
    onSuccess: () => toast.success("Match created successfully!"),
    onError: (error) => {
      console.error("Error creating match", error);
      toast.error("Failed to create match. Please try again.");
    },
  });

  const handleCreateMatch = () => {
    if (!userTeamId || !team2 || !matchTime) {
      alert("Please fill out all fields");
      return;
    }

    const formattedMatchTime = matchTime?.toISOString().slice(0, 16) || "";
    mutation.mutate({
      team1_id: userTeamId, // Team 1 is always the user's team
      team2_id: team2,
      match_time: formattedMatchTime,
    });
  };

  if (profileLoading || teamsLoading) return <div>Loading...</div>;
  if (teamsError) return <div>Error fetching teams</div>;

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col justify-center items-center">
        {/* Main Container */}
        <motion.div
          className="container mx-auto p-6 bg-white/5 backdrop-blur-lg shadow-xl max-w-md border border-purple-500 rounded-xl"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900 dark:text-white">
            Create a Match
          </h1>

          {/* Team 1 Selection (Locked) */}
          <div className="mb-6">
            <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
              Your Team{" "}
              <UsersIcon className="inline w-5 h-5 ml-2 text-indigo-500" />
            </label>
            <div className="relative w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white">
              {teams.find((team: any) => team.id === userTeamId)?.name ||
                "Your Team"}
            </div>
          </div>

          {/* Team 2 Selection */}
          <div className="mb-6">
            <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
              Select Opponent Team{" "}
              <UsersIcon className="inline w-5 h-5 ml-2 text-indigo-500" />
            </label>
            <Listbox value={team2} onChange={setTeam2}>
              <div className="relative">
                <ListboxButton className="relative w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600 focus:ring-4 focus:ring-indigo-500 transition">
                  <span className="block truncate dark:text-white">
                    {team2
                      ? teams.find((team: any) => team.id === team2)?.name
                      : "Choose Opponent Team"}
                  </span>
                </ListboxButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <ListboxOptions className="absolute z-10 w-full mt-1 max-h-60 bg-white dark:bg-gray-700 overflow-auto rounded-lg shadow-lg">
                    {teams
                      .filter((team: any) => team.id !== userTeamId) // Exclude user's own team
                      .map((team: any) => (
                        <ListboxOption
                          key={team.id}
                          value={team.id}
                          className={({ active, selected }) =>
                            `cursor-pointer select-none relative p-3 transition-all duration-300
                            ${
                              selected
                                ? "bg-blue-500 text-white"
                                : "text-gray-900 dark:text-gray-300"
                            }
                            ${
                              active
                                ? "bg-purple-400 hover:bg-purple-500 dark:bg-purple-700"
                                : ""
                            }`
                          }
                        >
                          {team.name}
                        </ListboxOption>
                      ))}
                  </ListboxOptions>
                </Transition>
              </div>
            </Listbox>
          </div>

          {/* Match Time Picker */}
          <div className="flex flex-col mb-6">
            <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
              Select Match Time{" "}
              <CalendarIcon className="inline w-5 h-5 ml-2 text-indigo-500" />
            </label>
            <ReactDatePicker
              selected={matchTime}
              onChange={(date: Date | null) => setMatchTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              placeholderText="Select a date and time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="w-full p-3 border-2 border-gray-300 dark:border-gray-600 rounded-lg dark:bg-gray-700 dark:text-white focus:ring-4 focus:ring-indigo-500 transition hover:border-indigo-500 hover:shadow-lg"
            />
          </div>

          {/* Submit Button */}
          <button
            onClick={handleCreateMatch}
            className={`w-full btn btn-outline btn-primary hover:scale-105 hover:shadow-glow`}
            disabled={teamsLoading}
          >
            {teamsLoading ? "Creating Match..." : "Create Match"}
          </button>

          {mutation.isError && (
            <div className="text-red-500 mt-4 dark:text-red-400">
              Error creating match
            </div>
          )}
          {mutation.isSuccess && (
            <div className="text-green-500 mt-4 dark:text-green-400">
              Match created successfully!
            </div>
          )}
        </motion.div>
      </div>
    </PageWrapper>
  );
};

export default CreateMatchPage;
