import React, { useState, useEffect } from "react";
import axios from "axios";
import { HeartIcon, ChatBubbleLeftIcon } from "@heroicons/react/20/solid";
import { useProfile } from "../../hooks/useProfile";
import PageWrapper from "../custom/PageWrapper";

interface Post {
  id: number;
  content: string;
  userID: number;
  createdAt: string;
  likes: number;
  comments: Comment[];
  user: {
    username: string;
    avatarUrl: string;
  };
}

interface Comment {
  id: number;
  content: string;
  userID: number;
  createdAt: string;
  user: {
    username: string;
    avatarUrl: string;
  };
}

const SocialFeed: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [newPost, setNewPost] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [commentText, setCommentText] = useState<{ [key: number]: string }>({});

  const {
    data: profile,
    isLoading: profileLoading,
    isError: profileError,
  } = useProfile(undefined);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/social/posts?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [page]);

  const handleCreatePost = async () => {
    const token = localStorage.getItem("token");
    if (!profile) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/social/posts`,
        { content: newPost },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPosts([response.data, ...posts]);
      setNewPost("");
    } catch (error) {
      console.error("Error creating post:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async (postId: number) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/social/posts/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPosts(posts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleDeleteComment = async (postId: number, commentId: number) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/social/posts/${postId}/comments/${commentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPosts(
        posts.map((post) =>
          post.id === postId
            ? {
                ...post,
                comments: post.comments.filter(
                  (comment) => comment.id !== commentId
                ),
              }
            : post
        )
      );
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleCommentSubmit = async (postID: number) => {
    const token = localStorage.getItem("token");
    const comment = commentText[postID];
    if (!comment) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/social/posts/${postID}/comments`,
        { content: comment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postID
            ? { ...post, comments: [...(post.comments || []), response.data] }
            : post
        )
      );
      setCommentText((prev) => ({ ...prev, [postID]: "" }));
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleLikePost = async (postID: number) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/social/posts/${postID}/like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPosts(
        posts.map((post) =>
          post.id === postID ? { ...post, likes: post.likes + 1 } : post
        )
      );
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  const handleNextPage = () => setPage((prevPage) => prevPage + 1);
  const handlePrevPage = () => setPage((prevPage) => Math.max(prevPage - 1, 1));

  if (profileLoading)
    return (
      <PageWrapper>
        <div>Loading...</div>
      </PageWrapper>
    );
  if (profileError)
    return (
      <PageWrapper>
        <div>Error loading profile</div>
      </PageWrapper>
    );

  return (
    <PageWrapper>
      <div className="social-feed min-h-screen text-base-content flex justify-center p-4">
        <div className="max-w-3xl w-full">
          <div className="flex flex-col items-center">
            <h2 className="text-2xl text-white font-bold mb-6">
              Player Social Feed
            </h2>
          </div>

          {
            <div className="create-post card border bg-white/5 backdrop-blur-lg border-purple-500 bg-base-200 shadow-md mb-6">
              <div className="card-body">
                <div className="flex items-center mb-4">
                  <img
                    src={profile?.avatarUrl}
                    alt={`${profile?.username}'s avatar`}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <h4 className="font-bold">{profile?.username}</h4>
                </div>
                <textarea
                  className="textarea textarea-bordered w-full mb-4"
                  value={newPost}
                  onChange={(e) => setNewPost(e.target.value)}
                  placeholder="What's on your mind?"
                ></textarea>
                <button
                  className={`btn btn-outline btn-primary hover:scale-105 hover:shadow-glow${
                    loading ? "loading" : ""
                  }`}
                  onClick={handleCreatePost}
                  disabled={loading}
                >
                  {loading ? "Posting..." : "Post"}
                </button>
              </div>
            </div>
          }

          {/* Feed */}
          {posts.map((post) => (
            <div
              key={post.id}
              className="post card border bg-base-200 shadow-md mb-4 bg-white/5 backdrop-blur-lg border-purple-500"
            >
              <div className="card-body">
                <div className="flex items-center mb-4">
                  <img
                    src={post.user?.avatarUrl || "/default-avatar.png"}
                    alt={`${post.user?.username || "Unknown User"}'s profile`}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <h4 className="font-bold">
                      {post.user?.username || "Unknown User"}
                    </h4>
                    <p className="text-xs text-gray-500">
                      Posted on: {new Date(post.createdAt).toLocaleString()}
                    </p>
                  </div>
                  {profile?.profile?.username === post.user.username && (
                    <button
                      onClick={() => handleDeletePost(post.id)}
                      className="ml-auto btn btn-sm btn-outline btn-error hover:scale-105 hover:shadow-glow"
                    >
                      Delete Post
                    </button>
                  )}
                </div>
                <p>{post.content}</p>

                <div className="flex items-center justify-between mt-4">
                  <button
                    onClick={() => handleLikePost(post.id)}
                    className="btn btn-sm btn-outline btn-accent"
                  >
                    <HeartIcon className="w-5 h-5 inline-block mr-1" />
                    Like ({post.likes})
                  </button>
                </div>

                {/* Comments Section */}
                <div className="comments mt-4">
                  {(post.comments ?? []).length > 0 && (
                    <div className="collapse collapse-arrow bg-base-300 rounded-lg">
                      <input type="checkbox" />
                      <div className="collapse-title">
                        Comments ({(post.comments ?? []).length})
                      </div>
                      <div className="collapse-content">
                        {(post.comments ?? []).map((comment) => (
                          <div key={comment.id} className="p-2 border-b">
                            <div className="flex items-center mb-2">
                              <img
                                src={
                                  comment.user?.avatarUrl ||
                                  "/default-avatar.png"
                                }
                                alt={`${
                                  comment.user?.username || "Unknown User"
                                }'s profile`}
                                className="w-8 h-8 rounded-full mr-3"
                              />
                              <div>
                                <h5 className="font-bold text-sm">
                                  {comment.user?.username || "Unknown User"}
                                </h5>
                                <p className="text-xs text-gray-500">
                                  Commented on:{" "}
                                  {new Date(comment.createdAt).toLocaleString()}
                                </p>
                              </div>
                              {profile?.profile?.username ===
                                comment.user.username && (
                                <button
                                  onClick={() =>
                                    handleDeleteComment(post.id, comment.id)
                                  }
                                  className="ml-auto btn btn-sm btn-outline btn-error hover:scale-105 hover:shadow-glow"
                                >
                                  Delete Comment
                                </button>
                              )}
                            </div>
                            <p>{comment.content}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="mt-4">
                    <textarea
                      className="textarea textarea-bordered w-full"
                      value={commentText[post.id] || ""}
                      onChange={(e) =>
                        setCommentText({
                          ...commentText,
                          [post.id]: e.target.value,
                        })
                      }
                      placeholder="Add a comment..."
                      rows={2}
                    ></textarea>
                    <button
                      className="btn btn-sm btn-outline btn-primary hover:scale-105 hover:shadow-glow mt-2"
                      onClick={() => handleCommentSubmit(post.id)}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Pagination */}
          <div className="pagination flex justify-between mt-6">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className={`btn btn-outline btn-primary hover:scale-105 hover:shadow-glow ${
                page === 1 ? "btn-disabled" : ""
              }`}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              className="btn btn-outline btn-primary hover:scale-105 hover:shadow-glow"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SocialFeed;
