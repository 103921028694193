import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";

const AppLayout: React.FC = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();

  return (
    <div>
      {/* Show Navbar if user is logged in and not on the login page */}
      {token && location.pathname !== "/" && <Navbar />}
      <Outlet /> {/* This will render the child route components */}
    </div>
  );
};

export default AppLayout;
