import React from "react";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

interface TeamData {
  teamLogo?: string;
  team?: string;
  members?: { team_id: number }[];
}

interface MatchProps {
  match: {
    ID: number;
    match_time: string;
    team1Data?: TeamData;
    team2Data?: TeamData;
  };
}

const UpcomingMatchCard: React.FC<MatchProps> = ({ match }) => {
  const team1Id = match.team1Data?.members?.[0]?.team_id;
  const team2Id = match.team2Data?.members?.[0]?.team_id;

  return (
    <div
      className="card shadow-lg w-full max-w-3xl mb-4 rounded-2xl transform transition-all hover:scale-105 duration-300 ease-out flex flex-col items-center p-3"
      style={{
        background:
          "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
        boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
      }}
    >
      <div className="flex flex-col gap-4 items-center pt-6">
        <div className="flex items-center gap-12">
          {/* Team 1 Logo and Name */}
          <div className="flex items-center gap-4">
            <img
              src={match.team1Data?.teamLogo || "default-logo-url"}
              alt={`${match.team1Data?.team || "Unknown"} logo`}
              className="w-12 h-12 rounded-full object-cover border border-purple-500 shadow-lg"
            />
            <Link
              to={`/team/${team1Id}`}
              className="text-2xl font-semibold text-white hover:text-purple-300 hover:scale-110 transition-colors"
            >
              {match.team1Data?.team || "Unknown"}
            </Link>
          </div>

          {/* VS Separator */}
          <span className="text-3xl font-semibold neon-text">vs</span>

          {/* Team 2 Logo and Name */}
          <div className="flex items-center gap-4">
            <img
              src={
                match.team2Data?.teamLogo ||
                "https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg"
              }
              alt={`${match.team2Data?.team || "Unknown"} logo`}
              className="w-12 h-12 rounded-full object-cover border border-purple-500 shadow-lg"
            />
            <Link
              to={`/team/${team2Id}`}
              className="text-2xl font-semibold text-white hover:text-purple-300 hover:scale-110 transition-colors"
            >
              {match.team2Data?.team || "Unknown"}
            </Link>
          </div>
        </div>

        {/* Match Time in Accent Pill */}
        <span className="text-md text-gray-300 bg-gray-800 border border-slate-500 px-3 py-1 rounded-full mt-2">
          {new Date(match.match_time).toLocaleString()}
        </span>
      </div>
    </div>
  );
};

interface UpcomingMatchesProps {
  matches: {
    ID: number;
    match_time: string;
    team1Data?: TeamData;
    team2Data?: TeamData;
  }[];
  loading: boolean; // New loading prop
}

const UpcomingMatches: React.FC<UpcomingMatchesProps> = ({
  matches,
  loading,
}) => {
  return (
    <div className="flex flex-col items-center">
      {loading ? (
        <div className="flex flex-col items-center">
          <HashLoader color="#6b46c1" size={50} />
          <p className="text-lg text-purple-500 mt-4">
            Fetching upcoming matches...
          </p>
        </div>
      ) : matches.length > 0 ? (
        matches.map((match) => (
          <UpcomingMatchCard key={match.ID} match={match} />
        ))
      ) : (
        <p className="text-white">No scheduled matches found.</p>
      )}
    </div>
  );
};

export default UpcomingMatches;
