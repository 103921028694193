import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ProfileDetails from "../components/personal_profile/ProfileDetails";
import RankInfo from "../components/personal_profile/RankInfo";
import RecentMatches from "../components/personal_profile/RecentMatches";
import { useProfile } from "../hooks/useProfile";
import {
  FaHourglassHalf,
  FaGamepad,
  FaLock,
  FaAngleDoubleDown,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { FaHandshakeAngle, FaCoins } from "react-icons/fa6";
import {
  GiCrossedSwords,
  GiPodium,
  GiOverkill,
  GiMedalSkull,
  GiLaurelCrown,
} from "react-icons/gi"; // Game Icons
import TeamDashboard from "../components/team_profile/TeamDashboard";
import PageWrapper from "../components/custom/PageWrapper";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";

interface Stats {
  game_mode: string;
  total_games: number;
  total_gold: number;
  wins: number;
  win_rate: number;
  total_damage: number;
  kills: number;
  losses: number;
  kda: string;
  deaths: number;
  avg_kills: number;
  avg_gold: number;
  avg_deaths: number;
  avg_assists: number;
  assists: number;
}

interface Statistics {
  matches_played: number;
  hours_played: number;
  avg_performance_score: number;
  avg_kdar: number;
  favorite_hero: {
    id: string;
    display_name: string;
  };
  favorite_role: string;
  winrate: number;
}

const ProfilePage: React.FC = () => {
  const { searchedPlayerName } = useParams<{ searchedPlayerName?: string }>();
  const [statsByGameMode, setStatsByGameMode] = useState<{
    [key: string]: Stats;
  }>({});
  const [statistics, setStatistics] = useState<Statistics>(); // New state for statistics endpoint
  const [selectedGameMode, setSelectedGameMode] = useState("ranked"); // Default tab
  const [isLoading, setIsLoading] = useState(true); // Single loading state
  const [globalLoading, setGlobalLoading] = useState(true); // New state for global loading overlay
  const {
    data: profile,
    isLoading: profileLoading,
    isError,
    error,
  } = useProfile(searchedPlayerName, true);
  const [showMore, setShowMore] = useState(false);

  const getRoleImage = (role: string) => `/roles/${role}.webp`;
  const getHeroImage = (hero: string) => `/heroes/${hero}.webp`;

  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!profileLoading && (profile?.playerId || profile?.id)) {
        try {
          // Fetch stats for all game modes
          const statsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/player/${
              profile?.playerId || profile?.id
            }/stats`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Fetch overall statistics
          const statisticsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/player/${
              profile?.playerId || profile?.id
            }/statistics`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Group stats by game mode
          const groupedStats = statsResponse.data.reduce(
            (acc: { [key: string]: Stats }, stat: Stats) => {
              acc[stat.game_mode] = stat;
              return acc;
            },
            {}
          );

          setStatsByGameMode(groupedStats);
          setStatistics(statisticsResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setGlobalLoading(false);
        }
      }
    };

    fetchData();
  }, [profileLoading, profile?.playerId, profile?.id]);

  const currentStats = statsByGameMode[selectedGameMode] || null;

  const isPageLoading = isLoading || profileLoading || globalLoading;
  const tabs = ["ranked", "pvp", "custom", "brawl"];

  const statsData = [
    {
      icon: <FaGamepad />,
      label: "Games Played",
      value: currentStats?.total_games,
      color: "black",
    },
    {
      icon: <FaHourglassHalf />,
      label: "Hours Played",
      value: statistics?.hours_played,
      color: "gray-500",
    },
    {
      icon: <GiCrossedSwords />,
      label: "Average KDA",
      value: currentStats?.kda,
      color: "purple-500",
    },
    {
      icon: statistics?.favorite_hero.id && (
        <img
          src={getHeroImage(statistics.favorite_hero.id)}
          alt={statistics.favorite_hero.id}
          className="w-14 h-14 rounded-full ring-2 ring-purple-500"
        />
      ),
      label: "Most Played Hero",
      value: statistics?.favorite_hero.display_name,
      color: "green-400",
    },
    {
      icon: statistics?.favorite_role && (
        <img
          src={getRoleImage(statistics.favorite_role)}
          alt={statistics.favorite_role}
          className="w-12 h-12"
        />
      ),
      label: "Most Played Role",
      value: statistics?.favorite_role
        ? capitalizeFirstLetter(statistics.favorite_role)
        : "N/A",
      color: "green-400",
    },
    {
      icon: <GiPodium />,
      label: "Win Rate",
      value: `${currentStats?.win_rate}%`,
      color: "blue-500",
    },
    {
      icon: <GiLaurelCrown />,
      label: "Wins",
      value: currentStats?.wins,
      color: "green-500",
    },
    {
      icon: <FaAngleDoubleDown />,
      label: "Losses",
      value: currentStats?.losses,
      color: "red-500",
    },
    {
      icon: <GiOverkill />,
      label: "Kills",
      value: currentStats?.kills,
      color: "red-500",
    },
    {
      icon: <GiMedalSkull />,
      label: "Deaths",
      value: currentStats?.deaths,
      color: "red-500",
    },
    {
      icon: <FaHandshakeAngle />,
      label: "Assists",
      value: currentStats?.assists,
      color: "blue-500",
    },
    {
      icon: <FaCoins />,
      label: "Average Gold",
      value: currentStats?.avg_gold,
      color: "yellow-500",
    },
  ];

  const alwaysVisibleStats = statsData.slice(0, 6);
  const expandableStats = statsData.slice(6);

  return (
    <PageWrapper key={searchedPlayerName}>
      <div className="min-h-screen px-6 py-8">
        {/* Loading State with Spinner and Animated Message */}
        {isPageLoading ? (
          <div className="flex flex-col justify-center items-center min-h-[75vh]">
            <HashLoader color="#6b46c1" size={75} />
            <div
              className="text-2xl font-bold mt-4"
              style={{
                animation:
                  "glow 2s ease-in-out infinite, pulse 2.5s ease-in-out infinite",
              }}
            >
              Preparing to dunk Orb Prime... hold tight, hero!
            </div>
          </div>
        ) : isError ? (
          <div>Error: {(error as Error).message}</div>
        ) : (
          <>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="w-full gap-8 mb-8"
            >
              {/* Main Content Grid */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full max-w-full">
                {/* Profile and Rank Info */}
                <div className="card flex flex-col items-center bg-gray-700/20 shadow-2xl p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-purple-400 mb-4">
                    Player Profile
                  </h2>

                  {/* Divider */}
                  <div className="divider text-gray-400">Profile Info</div>

                  {/* Player Profile Section */}
                  <div
                    className="card shadow-lg w-full max-w-xl mb-4 rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3"
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                      boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
                    }}
                  >
                    <ProfileDetails
                      username={
                        searchedPlayerName ||
                        profile?.username ||
                        "Unknown User"
                      }
                      steamId={profile?.steamId || "Unknown Steam ID"}
                      avatarUrl={profile?.avatarUrl || "/steam/default.jpg"}
                    />
                    <div className="mt-2 mb-2">
                      <RankInfo
                        rank={profile?.rank_title}
                        vpTotal={profile?.vp_total || 0}
                        size="extraLarge"
                      />
                    </div>
                  </div>

                  {/* Divider */}
                  <div className="divider text-gray-400">Player Statistics</div>

                  {/* Tabs for Game Modes */}
                  <div className="flex justify-center mb-4">
                    {tabs.map((tab) => (
                      <button
                        key={tab}
                        className={`px-4 py-2 rounded-lg mx-2 text-white hover:scale-110 ${
                          selectedGameMode === tab
                            ? "bg-purple-600 font-bold"
                            : "bg-gray-700"
                        }`}
                        onClick={() => setSelectedGameMode(tab)}
                      >
                        {tab.toUpperCase()}
                      </button>
                    ))}
                  </div>

                  {/* Player Statistics Section */}
                  <div className="flex justify-center items-center w-full">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 max-w-3xl px-6">
                      {/* Render always visible stats */}
                      {alwaysVisibleStats.map((stat, idx) => (
                        <motion.div
                          key={idx}
                          className="card shadow-lg rounded-lg flex flex-col items-center justify-center p-4"
                          style={{
                            width: "230px",
                            height: "130px",
                            background:
                              "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                            boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
                          }}
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.5 }}
                        >
                          {stat.icon && (
                            <div className={`text-${stat.color} text-3xl mb-2`}>
                              {stat.icon}
                            </div>
                          )}
                          <h3 className="text-sm font-medium text-gray-300 mb-1 text-center">
                            {stat.label}
                          </h3>
                          <p
                            className="text-2xl font-extrabold text-white text-center"
                            style={{
                              color: "#FFFFFF",
                              textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            {stat.value}
                          </p>
                        </motion.div>
                      ))}

                      {/* Render expandable stats */}
                      {showMore &&
                        expandableStats.map((stat, idx) => (
                          <motion.div
                            key={idx}
                            className="card shadow-lg rounded-lg flex flex-col items-center justify-center p-4"
                            style={{
                              width: "230px",
                              height: "130px",
                              background:
                                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                              boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
                            }}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                          >
                            {stat.icon && (
                              <div
                                className={`text-${stat.color} text-3xl mb-2`}
                              >
                                {stat.icon}
                              </div>
                            )}
                            <h3 className="text-sm font-medium text-gray-300 mb-1 text-center">
                              {stat.label}
                            </h3>
                            <p
                              className="text-2xl font-extrabold text-white text-center"
                              style={{
                                color: "#FFFFFF",
                                textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              {stat.value}
                            </p>
                          </motion.div>
                        ))}
                    </div>
                    <div className="flex justify-center items-center mt-4 cursor-pointer">
                      <span
                        className="flex flex-col items-center text-gray-400 text-sm hover:text-purple-400 transition duration-200"
                        onClick={() => setShowMore((prev) => !prev)}
                      >
                        <span>{showMore ? "Show Less" : "Show More"}</span>
                        {showMore ? (
                          <FaChevronUp className="text-lg mt-1" />
                        ) : (
                          <FaChevronDown className="text-lg mt-1" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Team Dashboard */}
                <div className="card flex flex-col items-center bg-gray-700/20 shadow-2xl p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-purple-400 mb-4">
                    Team Dashboard
                  </h2>
                  {profile?.teamId || profile?.team_id ? (
                    <TeamDashboard
                      searchedPlayerTeamId={profile.teamId || profile.team_id}
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full text-center">
                      <div className="text-gray-500 text-6xl mb-4">
                        <FaLock />
                      </div>
                      <p className="text-2xl font-semibold text-gray-400 mb-2">
                        No team data available
                      </p>
                      <p className="text-md text-gray-500 mb-4">
                        Join a team or create a new one to access team features.
                      </p>
                      <a
                        href="/team/create"
                        className="bg-purple-600 text-white px-6 py-2 rounded-lg text-xl font-bold hover:bg-purple-700 transition duration-200 flex items-center hover:scale-105"
                      >
                        <i className="fas fa-users mr-2"></i>{" "}
                        {/* Icon for team */}
                        Create a Team
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {/* Recent Matches Section */}

              <div className="w-full card bg-gray-700/20 p-6 mt-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-semibold text-purple-400 mb-4 text-center">
                  Recent Matches
                </h1>
                <div className="grid grid-cols-1 gap-4">
                  <RecentMatches
                    matches={profile?.recentMatches || []}
                    username={searchedPlayerName || profile?.username}
                    setGlobalLoading={setGlobalLoading}
                  />
                </div>
              </div>
            </motion.div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default ProfilePage;
