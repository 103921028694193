import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import heroes from "../../json/heroes.json";
import PageWrapper from "../custom/PageWrapper";
import { jwtDecode } from "jwt-decode";
import { useTeams } from "../../hooks/useTeams";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { FaBan, FaEye, FaEyeSlash, FaCopy, FaInfoCircle } from "react-icons/fa";

const Draft: React.FC = () => {
  const { team1_id, team2_id } = useParams<{
    team1_id: string;
    team2_id: string;
  }>();
  const { data: teams } = useTeams();

  // const draftCompletedRef = useRef(false); // Flag to track if the toast has been shown
  const navigate = useNavigate();

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [draftState, setDraftState] = useState<any>({
    id: null,
    team1_name: "Team 1",
    team2_name: "Team 2",
    team1_side: null, // Add this
    team2_side: null, // Add this
    actions: [], // Sequential actions (bans and picks)
    team1_bans: [],
    team2_bans: [],
    team1_picks: [],
    team2_picks: [],
    current_turn: "Loading...",
    action_type: "Loading...", // Ban or Pick
    time_remaining: 30,
    bonus_time: 0,
    team1_time_remaining: 30,
    team2_time_remaining: 30,
    timer_mode: "per_pick",
    completed: false,
    captain_team1_ready: false,
    captain_team2_ready: false, // Track readiness status
  });
  const [userRole, setUserRole] = useState<string>("observer");
  const [draftKey, setDraftKey] = useState<string>("");
  const [isKeyVisible, setIsKeyVisible] = useState<boolean>(false);
  const [showSideSelection, setShowSideSelection] = useState(false);
  const [coinflipWinner, setCoinflipWinner] = useState<string>("");
  const [showCoinflipModal, setShowCoinflipModal] = useState(false);
  const [userCoinflipChoice, setUserCoinflipChoice] = useState<string | null>(
    null
  );
  const [coinflipResult, setCoinflipResult] = useState<string | null>(null);

  const team1 = teams?.find((team: any) => team.id === Number(team1_id));
  const team2 = teams?.find((team: any) => team.id === Number(team2_id));
  const isDraftReady =
    draftState.captain_team1_ready && draftState.captain_team2_ready;

  const hasShownCoinFlipToast = useRef(false);
  const hasSelectedSide = useRef(false);
  const wsRef = useRef<WebSocket | null>(null);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (wsRef.current) {
      return;
    }

    // Clear any existing timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Set a new timeout to debounce the execution
    debounceRef.current = setTimeout(() => {
      const token = localStorage.getItem("token");
      let currentUserId: number | null = null;

      if (token) {
        const decoded: { user_id: number } = jwtDecode(token);
        currentUserId = decoded.user_id;
      }

      if (!currentUserId) {
        console.error("User ID not found. Make sure the user is logged in.");
        return;
      }

      // Extract query parameters
      const searchParams = new URLSearchParams(window.location.search);
      const timerMode = searchParams.get("timer_mode") || "per_pick";
      const timer = parseInt(searchParams.get("timer") || "30");
      const bonusTime = parseInt(searchParams.get("bonus_time") || "0");
      const allowMirrorPicks = searchParams.get("mirror") === "true";
      const enableBans = searchParams.get("bans") === "true";
      const mode = searchParams.get("mode") || "competitive";

      wsRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/ws/draft`
      );

      wsRef.current.onopen = () => {
        console.log("WebSocket connected");

        // Always send the `init` action upon connection
        wsRef.current?.send(
          JSON.stringify({
            action: "init",
            user_id: currentUserId,
            team1_id: parseInt(team1_id || ""),
            team2_id: parseInt(team2_id || ""),
            timer_mode: timerMode,
            timer,
            bonus_time: bonusTime,
            per_round_time: timer,
            allow_mirror_picks: allowMirrorPicks,
            enable_bans: enableBans,
            mode,
          })
        );
      };

      wsRef.current.onmessage = (event) => {
        if (!event.data) {
          console.error("WebSocket received an empty message.");
          return;
        }

        try {
          const data = JSON.parse(event.data);

          // Prevent multiple notifications
          if (data.message && data.message.includes("selected")) {
            if (!hasSelectedSide.current) {
              hasSelectedSide.current = true; // Mark it as handled immediately
              toast.success(data.message);
            }
          }

          // Set user role if provided
          if (data.draft_id) {
            setUserRole(data.role);
          }

          setDraftState((prevState: any) => {
            const newActions = [...(prevState.actions || [])];

            // Add new actions for bans or picks
            if (data.team1_bans?.length > prevState.team1_bans?.length) {
              newActions.push({
                type: "ban",
                team: "team1",
                hero: data.team1_bans[data.team1_bans.length - 1],
              });
            }
            if (data.team2_bans?.length > prevState.team2_bans?.length) {
              newActions.push({
                type: "ban",
                team: "team2",
                hero: data.team2_bans[data.team2_bans.length - 1],
              });
            }
            if (data.team1_picks?.length > prevState.team1_picks?.length) {
              newActions.push({
                type: "pick",
                team: "team1",
                hero: data.team1_picks[data.team1_picks.length - 1],
              });
            }
            if (data.team2_picks?.length > prevState.team2_picks?.length) {
              newActions.push({
                type: "pick",
                team: "team2",
                hero: data.team2_picks[data.team2_picks.length - 1],
              });
            }

            if (data.coinflip_result) {
              setCoinflipResult(data.coinflip_result);
              setCoinflipWinner(data.coinflip_winner);
              setShowCoinflipModal(false);
            }

            // Return the updated state
            return {
              ...prevState,
              ...data,
              actions: newActions,
              team1_side: data.team1_side, // Update team1Side
              team2_side: data.team2_side, // Update team2Side
            };
          });

          // Handle captain left case
          if (
            data.message &&
            data.message === "A captain has left the draft."
          ) {
            toast.warn("The draft has ended because one of the captains left.");
            navigate("/welcome");
            return;
          }

          if (data.error) {
            console.error("WebSocket Error:", data.error);
            return;
          }

          if (data.draft_key) {
            setDraftKey(data.draft_key);
          }

          console.log("WebSocket received draft state:", data);
        } catch (err) {
          console.error("Error parsing WebSocket message:", err, event.data);
        }
      };

      wsRef.current.onclose = (event) => {
        console.log("WebSocket closed", event.code, event.reason);
      };

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      setSocket(wsRef.current);
      return () => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ action: "leave" })); // Notify the server
        }
        wsRef.current?.close();
        wsRef.current = null;
      };
    }, 500); // Adjust the debounce delay here (500ms)

    // Clean up the timeout on component unmount or dependency change
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [userRole, team1_id, team2_id]);

  useEffect(() => {
    if (draftState.side_selection_pending && !coinflipResult) {
      setShowCoinflipModal(true);
    }
  }, [draftState.side_selection_pending, coinflipResult]);

  useEffect(() => {
    if (!coinflipWinner || !userRole) return;

    // Check if the current user is the coinflip winner
    const isCurrentUserWinner =
      (String(coinflipWinner) === String(team1_id) &&
        userRole === "captain_team1") ||
      (String(coinflipWinner) === String(team2_id) &&
        userRole === "captain_team2");

    if (isCurrentUserWinner) {
      setShowSideSelection(true);
    }

    const winningTeam =
      String(coinflipWinner) === String(team1_id)
        ? draftState.team1_name
        : draftState.team2_name;

    if (!hasShownCoinFlipToast.current) {
      toast.info(
        `${winningTeam} won the coin flip and must choose their side.`
      );
      hasShownCoinFlipToast.current = true;
    }
  }, [coinflipWinner, userRole, draftState.team1_name, draftState.team2_name]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(draftKey).then(() => {
      toast.success("Draft key copied to clipboard!");
    });
  };

  const sendAction = (type: string, hero: string) => {
    if (socket) {
      socket.send(
        JSON.stringify({
          action: type,
          draft_id: draftState.id,
          hero, // Use "EMPTY_BAN" for empty bans
        })
      );
    }
  };

  const handleCoinflipChoice = (choice: string) => {
    if (!socket) return;

    setUserCoinflipChoice(choice);
    socket.send(
      JSON.stringify({
        action: "coinflip",
        draft_id: draftState.id,
        choice,
      })
    );
  };

  const handleSideSelection = (side: string) => {
    if (socket) {
      // Send the side selection to the server
      socket.send(
        JSON.stringify({
          action: "select_side",
          draft_id: draftState.id,
          side,
        })
      );

      // Close the modal
      setShowSideSelection(false);
    }
  };

  const isActionAllowed = (type: string, hero: string) => {
    const isCorrectRole =
      (userRole === "captain_team1" &&
        draftState.current_turn === draftState.team1_name) ||
      (userRole === "captain_team2" &&
        draftState.current_turn === draftState.team2_name);

    const isBan = type === "ban" && draftState.action_type === "ban";
    const isPick = type === "pick" && draftState.action_type === "pick";

    const isHeroAvailable = () => {
      if (hero === "EMPTY_BAN") return true; // Allow "EMPTY_BAN" regardless of hero availability

      if (draftState.allow_mirror_picks) {
        // Mirror picks are allowed, but restrict the same team from picking the same hero more than once
        if (
          draftState.team1_picks.includes(hero) &&
          draftState.current_turn === draftState.team1_name
        ) {
          return false; // Hero already picked by Team 1
        }
        if (
          draftState.team2_picks.includes(hero) &&
          draftState.current_turn === draftState.team2_name
        ) {
          return false; // Hero already picked by Team 2
        }
        return true; // Allow if not already picked by the current team
      }

      // Default behavior when mirror picks are not allowed
      // Check both bans and picks
      const isBannedOrPicked =
        draftState.team1_bans.includes(hero) ||
        draftState.team2_bans.includes(hero) ||
        draftState.team1_picks.includes(hero) ||
        draftState.team2_picks.includes(hero);

      return !isBannedOrPicked; // Hero is available if not banned or picked
    };

    return isCorrectRole && (isBan || isPick) && isHeroAvailable();
  };

  const sendReady = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          action: "ready",
          draft_id: draftState.id,
        })
      );
    }
  };

  const renderTimer = () => {
    const totalDuration =
      draftState.timer_mode === "per_pick"
        ? draftState.timer
        : draftState.timer;

    const remainingTime =
      draftState.timer_mode === "per_pick"
        ? draftState.time_remaining
        : draftState.current_turn === draftState.team1_name
        ? draftState.team1_time_remaining
        : draftState.team2_time_remaining;

    const bonusTime =
      draftState.current_turn === draftState.team1_name
        ? draftState.bonus_time_team1
        : draftState.bonus_time_team2;

    const remainingPercentage = Math.max(
      (remainingTime / totalDuration) * 100,
      0
    );

    const getColor = (timePercentage: any) => {
      if (timePercentage > 50) return "text-green-500 bg-green-500";
      if (timePercentage > 20) return "text-yellow-500 bg-yellow-500";
      return "text-red-500 bg-red-500 animate-pulse";
    };

    const colors = getColor(remainingPercentage);

    return (
      <div className="mt-1">
        {/* Timer display */}
        <p
          className={`text-lg font-bold ${colors.split(" ")[0]} ${
            remainingPercentage < 20 ? "animate-glow" : ""
          }`}
        >
          🕒 Remaining: {remainingTime}s{" "}
          {bonusTime > 0 && `(+${bonusTime}s bonus)`}
        </p>

        {/* Progress bar */}
        <div className="relative w-full h-2 mt-1 bg-gray-300 rounded-full">
          <div
            className={`absolute h-full rounded-full ${
              remainingPercentage < 20 ? "animate-pulse-bar" : ""
            } transition-all duration-500`}
            style={{
              width: `${remainingPercentage}%`,
              background:
                "linear-gradient(to right, #4ade80, #facc15, #ef4444)",
            }}
          ></div>
        </div>
      </div>
    );
  };

  const renderActionButton = (type: string, hero: string) => {
    const isAllowed = isActionAllowed(type, hero);

    return (
      <button
        className={`btn btn-xs px-2 py-1`}
        style={{
          opacity: isAllowed && isDraftReady ? 1 : 0.4,
          cursor: isAllowed && isDraftReady ? "pointer" : "not-allowed",
          backgroundColor:
            isAllowed && isDraftReady
              ? type === "ban"
                ? "#E53E3E" // Red for Ban
                : "#38A169" // Green for Pick
              : "#4A5568", // Gray for disabled
          color: "#FFFFFF", // Ensure text is visible
        }}
        onClick={() => isAllowed && isDraftReady && sendAction(type, hero)}
        disabled={!isAllowed || !isDraftReady}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </button>
    );
  };

  const renderTeamTimeline = (team: string) => {
    return draftState.actions
      .filter((action: any) => action.team === team)
      .map((action: any, index: number) => {
        const isBanned = action.type.toLowerCase() === "ban";

        return (
          <div
            key={index}
            className="relative flex flex-col items-center gap-1 p-2 rounded-lg"
          >
            {/* Hero Image */}
            <div className="relative">
              <img
                src={
                  action.hero === "EMPTY_BAN"
                    ? `/steam/default.jpg`
                    : heroes.find((h) => h.name === action.hero)?.image
                }
                alt={action.hero}
                className={`w-12 h-12 object-cover rounded-lg ${
                  isBanned ? "opacity-30" : ""
                }`}
              />
              {/* Ban Icon */}
              {isBanned && (
                <div
                  className="absolute text-red-600"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "2rem", // Adjust the size of the icon
                  }}
                >
                  <FaBan />
                </div>
              )}
            </div>
            {/* Hero Name */}
            <p className="text-xm font-bold text-gray-300">{action.hero}</p>
          </div>
        );
      });
  };

  return (
    <PageWrapper>
      {/* Coinflip Modal */}
      {showCoinflipModal && userRole === "captain_team1" && (
        <div className="modal modal-open">
          <div className="modal-box bg-base-200 border-2 border-purple-500 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-center mb-6 text-white">
              Choose heads or tails for the coinflip:
            </h3>
            <div className="flex justify-around gap-4">
              <button
                className="btn btn-primary btn-outline min-w-[150px] text-lg font-semibold rounded-lg hover:scale-105 transition-transform"
                onClick={() => handleCoinflipChoice("heads")}
                disabled={!!userCoinflipChoice}
              >
                Heads
              </button>
              <button
                className="btn btn-secondary btn-outline min-w-[150px] text-lg font-semibold rounded-lg hover:scale-105 transition-transform"
                onClick={() => handleCoinflipChoice("tails")}
                disabled={!!userCoinflipChoice}
              >
                Tails
              </button>
            </div>
            {userCoinflipChoice && (
              <p className="text-center mt-6 text-base-content">
                You selected:{" "}
                <strong className="text-primary">
                  {userCoinflipChoice.toUpperCase()}
                </strong>
              </p>
            )}
            {coinflipResult && (
              <p className="text-center mt-4 text-base-content">
                Coinflip result:{" "}
                <strong className="text-secondary">
                  {coinflipResult.toUpperCase()}
                </strong>
              </p>
            )}
          </div>
        </div>
      )}

      {/* Side Selection Modal */}
      {showSideSelection && (
        <div className="modal modal-open">
          <div className="modal-box bg-base-200 border-2 border-purple-500 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-center mb-6 text-white">
              You won the coin flip! Choose your side:
            </h3>
            <div className="flex justify-around gap-4">
              <button
                className="btn btn-primary btn-outline min-w-[150px] text-lg font-semibold rounded-lg hover:scale-105 transition-transform"
                onClick={() => handleSideSelection("Dusk")}
              >
                Dusk
              </button>
              <button
                className="btn btn-secondary btn-outline min-w-[150px] text-lg font-semibold rounded-lg hover:scale-105 transition-transform"
                onClick={() => handleSideSelection("Dawn")}
              >
                Dawn
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="p-4 min-h-screen flex flex-col items-center bg-gray-700/20">
        <div className="relative flex flex-row items-stretch justify-between w-full max-w-[95vw] min-h-[85vh] gap-2">
          {/* Team 1 Panel */}
          <div className="team-panel flex flex-col items-center w-1/8 bg-gray-700/20 p-3 rounded-lg">
            <div className="flex flex-col items-center mb-4">
              {team1?.team_logo && (
                <img
                  src={team1.team_logo}
                  alt={team1.name}
                  className="w-16 h-16 object-cover rounded-full mb-2 ring-4 ring-purple-800"
                />
              )}
              <h3 className="text-lg font-bold text-white text-center">
                {draftState.team1_name}
              </h3>
              <p className="text-sm text-gray-400">
                Side: {draftState.team1_side}
              </p>{" "}
              {/* Add this */}
            </div>
            {/* Timeline for Team 1 */}
            <div className="timeline flex flex-col items-center">
              {renderTeamTimeline("team1")}
            </div>
          </div>

          {/* Hero Grid */}
          <div className="relative flex flex-col items-center w-5/6 bg-gray-700/20 p-4 rounded-lg">
            {/* Captain Readiness - Team 1 */}
            <div className="absolute top-2 left-2 flex flex-col items-start p-2 rounded-lg">
              <p className="text-xs text-gray-300 mb-1">Captain Readiness</p>
              {userRole === "captain_team1" ? (
                <button
                  className={`btn btn-xs ${
                    draftState.captain_team1_ready
                      ? "btn-success"
                      : "btn-primary"
                  }`}
                  onClick={sendReady}
                  disabled={draftState.captain_team1_ready}
                >
                  {draftState.captain_team1_ready ? "Ready!" : "Mark Ready"}
                </button>
              ) : (
                <p
                  className={`text-xs ${
                    draftState.captain_team1_ready
                      ? "text-green-500"
                      : "text-gray-400"
                  }`}
                >
                  {draftState.captain_team1_ready ? "Ready" : "Not Ready"}
                </p>
              )}
            </div>

            {/* Captain Readiness - Team 2 */}
            <div className="absolute top-2 right-2 flex flex-col items-end p-2 rounded-lg">
              <p className="text-xs text-gray-300 mb-1">Captain Readiness</p>
              {userRole === "captain_team2" ? (
                <button
                  className={`btn btn-xs ${
                    draftState.captain_team2_ready
                      ? "btn-success"
                      : "btn-primary"
                  }`}
                  onClick={sendReady}
                  disabled={draftState.captain_team2_ready}
                >
                  {draftState.captain_team2_ready ? "Ready!" : "Mark Ready"}
                </button>
              ) : (
                <p
                  className={`text-xs ${
                    draftState.captain_team2_ready
                      ? "text-green-500"
                      : "text-gray-400"
                  }`}
                >
                  {draftState.captain_team2_ready ? "Ready" : "Not Ready"}
                </p>
              )}
            </div>

            <div className="text-center mb-4">
              {draftState.completed ? (
                <div className="text-center mb-4">
                  <p className="text-sm font-bold text-green-400">
                    The draft has been completed!
                  </p>
                  {/* Draft Key Display - IT WILL BE HANDLED FROM BACKEND */}
                  <div className="flex items-center text-gray-300 px-4 py-2 mt-4 rounded-lg">
                    <span className="text-gray-500">
                      <span className="font-bold text-gray-300 text-sm">
                        {" "}
                        {"Lobby Key"}:{" "}
                      </span>{" "}
                      {isKeyVisible ? draftKey : "••••••••••••"}
                    </span>
                    <button
                      className="ml-2 text-gray-500 hover:text-gray-300"
                      onClick={() => setIsKeyVisible(!isKeyVisible)}
                    >
                      {isKeyVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    <button
                      className="ml-2 text-gray-500 hover:text-gray-300"
                      onClick={copyToClipboard}
                    >
                      <FaCopy />
                    </button>
                    <button
                      className="ml-2 text-gray-500 hover:text-yellow-500"
                      title="This is a unique draft key for reference"
                    >
                      <FaInfoCircle />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex items-center justify-center gap-2">
                    {draftState.current_turn === draftState.team1_name &&
                      team1?.team_logo && (
                        <img
                          src={team1.team_logo}
                          alt={`${draftState.team1_name} logo`}
                          className="w-8 h-8 object-cover rounded-full"
                        />
                      )}
                    {draftState.current_turn === draftState.team2_name &&
                      team2?.team_logo && (
                        <img
                          src={team2.team_logo}
                          alt={`${draftState.team2_name} logo`}
                          className="w-8 h-8 object-cover rounded-full"
                        />
                      )}
                    <p className="text-sm font-bold text-yellow-400">
                      {draftState.current_turn} is making a{" "}
                      {draftState.action_type}.
                    </p>
                  </div>
                  {draftState.captain_team1_ready &&
                  draftState.captain_team2_ready
                    ? renderTimer()
                    : null}
                </>
              )}
            </div>

            <div className="divider text-gray-500">Hero Selection</div>

            <div className="grid grid-cols-5 sm:grid-cols-6 lg:grid-cols-12 gap-5 w-full h-full">
              {/* "No Ban" Option */}
              {draftState.action_type === "ban" && draftState.enable_bans && (
                <motion.div
                  className={`shadow-lg rounded-lg flex flex-col items-center justify-center p-2 ${
                    isActionAllowed("ban", "EMPTY_BAN")
                      ? "hover:shadow-xl cursor-pointer"
                      : "opacity-50 pointer-events-none"
                  }`}
                  style={{
                    width: "100px",
                    height: "140px",
                    background:
                      "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                    boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
                  }}
                  onClick={() => {
                    if (isActionAllowed("ban", "EMPTY_BAN"))
                      sendAction("ban", "EMPTY_BAN");
                  }}
                >
                  <p className="text-xs font-bold text-gray-300 text-center mb-2">
                    No Bans
                  </p>
                  <p className="text-xs text-gray-400 text-center">
                    Skip this ban phase.
                  </p>
                </motion.div>
              )}
              {heroes
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                .map((hero) => {
                  const isPickedOrBanned =
                    draftState.team1_bans.includes(hero.name) ||
                    draftState.team2_bans.includes(hero.name) ||
                    draftState.team1_picks.includes(hero.name) ||
                    draftState.team2_picks.includes(hero.name);

                  const isDisabled =
                    isPickedOrBanned && !draftState.allow_mirror_picks;

                  return (
                    <motion.div
                      key={hero.id}
                      className={`shadow-lg rounded-lg flex flex-col items-center justify-between p-2 ${
                        isDisabled
                          ? "opacity-50 pointer-events-none"
                          : "hover:shadow-xl"
                      }`}
                      style={{
                        width: "100px",
                        height: "160px",
                        background:
                          "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                        boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
                      }}
                    >
                      <div className="flex-shrink-0 h-20 w-full overflow-hidden">
                        <img
                          src={hero.image}
                          alt={hero.name}
                          className="w-full h-full object-cover rounded-t-md"
                          style={{
                            filter: isDisabled ? "grayscale(100%)" : "none",
                          }}
                        />
                      </div>
                      <h3 className="text-xs font-bold text-gray-300 text-center mt-2">
                        {hero.name}
                      </h3>
                      <div className="flex gap-1 mt-1">
                        {renderActionButton("ban", hero.name)}
                        {renderActionButton("pick", hero.name)}
                      </div>
                    </motion.div>
                  );
                })}
            </div>
          </div>

          {/* Team 2 Panel */}
          <div className="team-panel flex flex-col items-center w-1/8 bg-gray-700/20 p-4 rounded-lg">
            <div className="flex flex-col items-center mb-4">
              {team2?.team_logo && (
                <img
                  src={team2.team_logo}
                  alt={team2.name}
                  className="w-16 h-16 object-cover rounded-full mb-2 ring-4 ring-purple-800"
                />
              )}
              <h3 className="text-lg font-bold text-white text-center">
                {draftState.team2_name}
              </h3>
              <p className="text-sm text-gray-400">
                Side: {draftState.team2_side}
              </p>{" "}
              {/* Add this */}
            </div>
            {/* Timeline for Team 2 */}
            <div className="timeline flex flex-col items-center">
              {renderTeamTimeline("team2")}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Draft;
