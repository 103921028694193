import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchProfile = async (
  searchedPlayerName: string | undefined,
  forceRefetch = false
) => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("No token found. Please log in.");
  }

  if (!searchedPlayerName) {
    const cachedProfile = localStorage.getItem("profile");
    if (cachedProfile && !forceRefetch) {
      return JSON.parse(cachedProfile);
    }

    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/profile`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (!forceRefetch) {
      localStorage.setItem("profile", JSON.stringify(response.data));
    }

    return response.data;
  }

  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/player/search?username=${searchedPlayerName}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const useProfile = (
  searchedPlayerName: string | undefined,
  forceRefetch = false
) => {
  return useQuery({
    queryKey: ["profile", searchedPlayerName, forceRefetch],
    queryFn: () => fetchProfile(searchedPlayerName, forceRefetch),
    refetchOnWindowFocus: false,
  });
};
