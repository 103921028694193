import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchTeamDetails = async (teamId: number) => {
  if (!teamId) {
    throw new Error("No team ID found.");
  }

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found. Please log in.");
  }

  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

// Custom hook for fetching team details using React Query
export const useTeamDetails = (teamId: number | null) => {
  const query = useQuery({
    queryKey: ["teamDetails", teamId],
    queryFn: () => fetchTeamDetails(teamId as number),
    enabled: !!teamId,
    refetchOnWindowFocus: false,
  });

  // Return both data and refetch
  return {
    ...query,
    refetch: query.refetch,
  };
};
