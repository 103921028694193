// src/pages/CreateTournamentPage.tsx
import { useTournaments } from "../hooks/useTournaments";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../components/custom/PageWrapper";

const CreateTournamentPage = () => {
  const { createTournamentMutation } = useTournaments();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [maxTeams, setMaxTeams] = useState(8);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const navigate = useNavigate(); // Initialize navigate

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formattedStartDate = new Date(startDate).toISOString();
    const formattedEndDate = new Date(endDate).toISOString();
    
    createTournamentMutation.mutate(
      {
        name,
        description,
        max_teams: maxTeams,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      },
      {
        onSuccess: () => {
          navigate("/tournaments"); // Redirect on success
        },
      }
    );
  };

  return (
    <PageWrapper>
      <div className="flex justify-center items-center min-h-screen">
        <form
          onSubmit={handleSubmit}
          className="card w-full max-w-lg shadow-lg border border-purple-500 p-6"
        >
          <h1 className="text-3xl font-bold mb-6 text-center">
            Create Tournament
          </h1>
          <div className="form-control mb-4">
            <label className="label font-semibold">Tournament Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter tournament name"
              className="input input-bordered"
            />
          </div>
          <div className="form-control mb-4">
            <label className="label font-semibold">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the tournament"
              className="textarea textarea-bordered"
            />
          </div>
          <div className="form-control mb-4">
            <label className="label font-semibold">Max Teams</label>
            <input
              type="number"
              value={maxTeams}
              onChange={(e) => setMaxTeams(parseInt(e.target.value))}
              placeholder="Set max teams"
              className="input input-bordered"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-control">
              <label className="label font-semibold">Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="input input-bordered"
              />
            </div>
            <div className="form-control">
              <label className="label font-semibold">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="input input-bordered"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="btn btn-outline btn-secondary hover:scale-105 hover:shadow-glow btn-wide mt-4"
            >
              Create Tournament
            </button>
          </div>
        </form>
      </div>
    </PageWrapper>
  );
};

export default CreateTournamentPage;
