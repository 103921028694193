import React, { useEffect, useState } from "react";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { MdOutlineLeaderboard } from "react-icons/md";
import { useTeamDetails } from "../../hooks/useTeamDetails";
import useTeamStats from "../../hooks/useTeamStats";
import { FaGamepad, FaHandsHelping } from "react-icons/fa";
import { GiCrossedSwords, GiPodium, GiDeathSkull, GiSwordsEmblem, GiDeathZone, GiEyeTarget } from "react-icons/gi"; // Game Icons
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTournaments } from "../../hooks/useTournaments";
import axios from "axios";
import { useProfile } from "../../hooks/useProfile";

interface TeamDashboardProps {
  searchedPlayerTeamId?: number;
}

interface TeamHistory {
  id: number;
  player_id: number;
  team_id: number;
  team_name: string;
  joined_at: string;
  left_at: string | null;
}

const TeamDashboard: React.FC<TeamDashboardProps> = ({
  searchedPlayerTeamId,
}) => {
  const teamId = searchedPlayerTeamId;

  const { searchedPlayerName } = useParams<{ searchedPlayerName?: string }>();
  const { data: teamDetails, isLoading, isError } = useTeamDetails(teamId!);
  const { teamStats } = useTeamStats(teamId!);
  const { tournamentsQuery } = useTournaments(teamId);
  const [teamHistory, setTeamHistory] = useState<TeamHistory[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown visibility

  const { data: profile } = useProfile(searchedPlayerName);

  const tournaments = tournamentsQuery.data || [];

  useEffect(() => {
    const fetchTeamHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/player/${profile?.id}/teams/history`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setTeamHistory(response.data);
      } catch (error) {
        console.error("Error fetching team history:", error);
      }
    };

    if (profile?.id) {
      fetchTeamHistory();
    }
  }, [profile?.id]);

  if (!teamId || teamId === 0) {
    return (
      <div className="flex justify-center">
        <div>No team data available.</div>
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading team details...</div>;
  }

  if (isError) {
    return <div>Error loading team details</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center rounded-xl w-full">
      {/* Divider */}
      <div className="divider text-gray-400">Registered Tournaments</div>
      {/* Registered Tournament Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 w-full max-w-4xl">
        {tournaments.map((tournament) => (
          <Link
            key={tournament.id}
            to={`/tournaments/${tournament.id}`}
            className="transform transition-all duration-300 ease-out hover:scale-105"
          >
            <motion.div
              className="card shadow-lg rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer w-full"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)",
              }}
            >
              <h3 className="text-lg font-semibold text-gray-200 flex items-center space-x-2">
                <FaTrophy className="text-yellow-400" />
                <span>{tournament.name}</span>
              </h3>
              <p className="text-md text-gray-400 mt-2 mb-2 text-center">
                {tournament.description}
              </p>
              <p className="text-sm text-gray-500">
                Start Date:{" "}
                {new Date(tournament.start_date).toLocaleDateString()}
              </p>
            </motion.div>
          </Link>
        ))}
      </div>

      {/* Divider */}
      <div className="divider text-gray-400">Team Profile</div>

      {/* Team Profile Card Section */}
      <div
        className="card shadow-lg w-full max-w-2xl mb-8 rounded-2xl transform transition-all duration-300 ease-out flex flex-col p-3"
        style={{
          background:
            "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
          boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
        }}
      >
        {/* Top Column - Team Name, Logo, Captain, Centered */}
        <div className="flex flex-col items-center justify-center w-full">
          <Link
            to={`/team/${teamId}`}
            className="text-3xl font-bold text-white text-center hover:scale-110"
          >
            {teamDetails?.team}
          </Link>
          <img
            src={
              teamDetails?.teamLogo
                ? teamDetails?.teamLogo
                : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
            }
            alt={`${teamDetails?.team} logo`}
            className="w-24 h-24 object-cover rounded-full shadow-lg mt-4 border-2 border-purple-500"
          />
          <div className="mt-6 text-center">
            <p className="text-lg text-white flex items-center justify-center space-x-2">
              <span className="bg-gradient-to-r from-yellow-400 to-orange-500 text-xs text-black font-bold px-2 py-1 rounded-full">
                Captain
              </span>
              <span>{teamDetails?.captain}</span>
            </p>
          </div>
        </div>

        {/* Bottom Column - Team Stats */}
        <div className="flex flex-col items-center justify-center mt-8 space-y-6 md:flex-row md:space-y-0 md:space-x-4">
          {/* Wins Box */}
          <div
            className="card bg-gray-800/30 text-white font-bold min-w-[10rem] h-[5rem] flex flex-col justify-center items-center rounded-lg shadow-lg"
            style={{
              background:
                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
              boxShadow: "2px 1px 2px 2px rgba(139, 92, 246, 0.4)", // soft purple outer glow
            }}
          >
            <span className="bg-gradient-to-r from-green-300 to-green-700 text-sm text-black font-bold px-2 py-1 rounded-full">
              Wins
            </span>
            <p className="text-2xl">{teamDetails?.wins}</p>
          </div>
          {/* Losses Box */}
          <div
            className="card bg-gray-800/40 text-white font-bold min-w-[10rem] h-[5rem] flex flex-col justify-center items-center rounded-lg shadow-lg"
            style={{
              background:
                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
              boxShadow: "2px 1px 2px 2px rgba(139, 92, 246, 0.4)", // soft purple outer glow
            }}
          >
            <span className="bg-gradient-to-r from-red-300 to-red-700 text-sm text-black font-bold px-2 py-1 rounded-full">
              Losses
            </span>
            <p className="text-2xl">{teamDetails?.losses}</p>
          </div>
        </div>

        {/* Previous Team - Dropdown */}
        <div className="flex justify-start ml-4 mb-8 mt-8">
          {/* Dropdown toggle button */}
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className=" btn btn-ghost btn-outline btn-secondary text-white px-4 py-2 rounded-lg font-bold"
          >
            Previous Teams
          </button>
          {/* Dropdown content */}
          {isDropdownOpen && (
            <div
              className=" card flex justify-between shadow-lg rounded-md py-2 px-4 ml-4 max-h-48 overflow-y-auto"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "0px 4px 15px rgba(139, 92, 246, 0.5)",
              }}
            >
              {teamHistory.length > 0 ? (
                teamHistory.map((history, index) => (
                  <div
                    key={index}
                    className="flex items-center text-white border-b last:border-0 py-2"
                  >
                    <div className="font-semibold mr-4 w-1/3">
                      {history.team_name}
                    </div>
                    <div className="text-sm text-white mr-4 w-1/3">
                      Joined: {new Date(history.joined_at).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-white w-1/3">
                      Left:{" "}
                      {history.left_at
                        ? new Date(history.left_at).toLocaleDateString()
                        : "Present"}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-gray-500">No previous teams</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Divider */}
      <div className="divider text-gray-400">Team Statistics</div>

      {/* Team Statistics Section*/}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-4 w-full max-w-3xl px-6 pt-2">
        {[
          {
            icon: <FaGamepad />,
            label: "Games Played",
            value: teamStats.gamesPlayed,
            color: "black",
          },
          {
            icon: <GiSwordsEmblem />,
            label: "Kills",
            value: teamStats.totalKills,
            color: "gray-500",
          },
          {
            icon: <GiDeathZone />,
            label: "Deaths",
            value: teamStats.totalDeaths,
            color: "red-500",
          },
          {
            icon: <FaHandsHelping />,
            label: "Assists",
            value: teamStats.totalAssists,
            color: "blue-500",
          },
          {
            icon: <GiEyeTarget />,
            label: "Wards",
            value: teamStats.totalWardsPlaced,
            color: "green-400",
          },
          {
            icon: <MdOutlineLeaderboard />,
            label: "Win/Loss Ratio",
            value: `${(teamStats.winLossRatio * 100).toFixed(2)}%`,
            color: "purple-500",
          },
        ].map((stat, idx) => (
          <motion.div
            key={idx}
            className="card shadow-lg rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3 hover:scale-105"
            style={{
              background:
                "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
              boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            {stat.icon && (
              <div className={`text-${stat.color} text-3xl mb-2`}>
                {stat.icon}
              </div>
            )}
            <h3 className="text-md font-semibold text-gray-200">
              {stat.label}
            </h3>
            <p className="text-xl font-bold text-white">{stat.value}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default TeamDashboard;
