import React from "react";

const FeaturesSection: React.FC = () => {
  const features = [
    {
      title: "Create Matches",
      description: "Seamlessly schedule and compete in matches.",
      icon: "🎮",
    },
    {
      title: "Track Stats",
      description: "Monitor your team’s performance in real-time.",
      icon: "📊",
    },
    {
      title: "Join the Community",
      description: "Connect with other players in social feeds.",
      icon: "🤝",
    },
  ];

  return (
    <section className="py-20 text-white">
      <div className="container mx-auto">
        <h2 className="text-center text-3xl font-bold mb-10">Features</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, idx) => (
            <div
              key={idx}
              className="p-6 bg-white/5 backdrop-blur-lg max-w-md border border-purple-500 rounded-lg shadow-md text-center space-y-4 transform transition-all duration-300 hover:scale-105 hover:border-blue-400"
            >
              <div className="text-4xl">{feature.icon}</div>
              <h3 className="text-2xl font-bold text-blue-300">
                {feature.title}
              </h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
