import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";
import HomePage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import NewsFeed from "./components/news/NewsFeed";
import SocialFeed from "./components/social/SocialFeed";
import Draft from "./components/Draft/Draft";
import CreateTeamPage from "./pages/CreateTeamPage";
import TeamProfilePage from "./pages/TeamProfilePage";
import CreateMatchPage from "./pages/CreateMatchPage";
import TeamLandingPage from "./pages/TeamLandingPage";
import PlayerResultsPage from "./pages/PlayerResultsPage";
import TeamResultsPage from "./pages/TeamResultsPage";
import PlayerTeamResultsPage from "./pages/PlayerTeamResultsPage";
import ScheduledMatchesPage from "./pages/ScheduledMatchesPage";
import GetStartedPage from "./pages/GetStartedPage";
import RegisterPage from "./pages/RegisterPage";
import AppLayout from "./AppLayout"; // Import the new layout component
import { useTokenRefresh } from "./hooks/useTokenRefresh";
import LandingPage from "./pages/LandingPage";
import { useAuth } from "./hooks/useAuth";
import LeaderboardPage from "./pages/LeaderboardPage";
import { WebSocketProvider } from "./context/WebSocketContext";
import TournamentsPage from "./pages/TournamentsPage";
import TournamentDetailsPage from "./pages/TournamentDetailsPage";
import CreateTournamentPage from "./pages/CreateTournamentPage";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import DraftLobbyPage from "./pages/DraftLobbyPage";
import PatchNotesPage from "./pages/PatchNotesPage";
import BugReportPage from "./pages/BugReportPage";

const queryClient = new QueryClient();

// Apply dark mode based on localStorage on app initialization
const currentMode = localStorage.getItem("darkMode");
if (currentMode === "true") {
  document.documentElement.classList.add("dark");
} else if (currentMode === "false") {
  document.documentElement.classList.remove("dark");
}

const App: React.FC = () => {
  return (
    <DarkModeProvider>
      <QueryClientProvider client={queryClient}>
        <WebSocketProvider url={`${process.env.REACT_APP_WEBSOCKET_URL}/ws`}>
          <Router>
            {/* Call the useTokenRefresh hook after Router is initialized */}
            <AuthWrapper />
            <TokenRefreshWrapper />

            <Routes>
              {/* Public Route for HomePage */}
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<RegisterPage />} />

              {/* All other routes wrapped in AppLayout */}
              <Route element={<AppLayout />}>
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/profile/:searchedPlayerName"
                  element={<ProfilePage />}
                />
                <Route path="/welcome" element={<LandingPage />} />
                <Route path="/news" element={<NewsFeed />} />
                <Route path="/patch-notes" element={<PatchNotesPage />} />
                <Route path="/bug-reports" element={<BugReportPage />} />
                <Route path="/social" element={<SocialFeed />} />
                <Route path="/players" element={<PlayerResultsPage />} />
                <Route
                  path="/player-teams"
                  element={<PlayerTeamResultsPage />}
                />
                <Route path="/teams" element={<TeamResultsPage />} />
                <Route path="/get-started" element={<GetStartedPage />} />
                <Route path="/leaderboard" element={<LeaderboardPage />} />
                <Route path="/team/create" element={<CreateTeamPage />} />
                <Route path="/team/landing" element={<TeamLandingPage />} />
                <Route path="/team/:team_id" element={<TeamProfilePage />} />
                <Route path="/match/create" element={<CreateMatchPage />} />
                <Route path="/tournaments" element={<TournamentsPage />} />
                <Route path="/draft/:team1_id/:team2_id" element={<Draft />} />
                <Route path="/draft/lobby" element={<DraftLobbyPage />} />
                <Route
                  path="/tournaments/:id"
                  element={<TournamentDetailsPage />}
                />
                <Route
                  path="/create-tournament"
                  element={<CreateTournamentPage />}
                />
                <Route
                  path="/match/scheduled"
                  element={<ScheduledMatchesPage />}
                />
              </Route>
            </Routes>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              theme="dark"
            />
          </Router>
        </WebSocketProvider>
      </QueryClientProvider>
    </DarkModeProvider>
  );
};

const TokenRefreshWrapper: React.FC = () => {
  useTokenRefresh(); // Ensure it's within the Router context
  return null;
};

const AuthWrapper: React.FC = () => {
  useAuth();
  return null;
};

export default App;
