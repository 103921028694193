import React, { useState, useEffect } from "react";
import { useTeams } from "../../hooks/useTeams";
import { FaTrophy } from "react-icons/fa";
import PageWrapper from "../custom/PageWrapper";

interface Team {
  id: number;
  name: string;
  wins: number;
  losses: number;
  team_logo: string;
}

const TopTeamsCarousel: React.FC = () => {
  const { data: teams, isLoading, error } = useTeams();
  const [topTeams, setTopTeams] = useState<Team[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (teams) {
      const sortedTopTeams = (teams as Team[])
        .sort((a, b) =>
          b.wins === a.wins ? a.losses - b.losses : b.wins - a.wins
        )
        .slice(0, 3);
      setTopTeams(sortedTopTeams);
    }
  }, [teams]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % topTeams.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? topTeams.length - 1 : prevSlide - 1
    );
  };

  if (isLoading)
    return <div className="text-center text-white">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500">Failed to load teams.</div>
    );

  return (
    <section className="py-12 text-white">
      <div className="container mx-auto px-2">
        <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-200">
          Top Teams
        </h2>
        <div className="relative w-full max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg">
          <div className="carousel w-full">
            {topTeams.map((team, index) => (
              <div
                key={team.id}
                className={`carousel-item w-full transition-opacity duration-500 ${
                  index === currentSlide
                    ? "block opacity-100"
                    : "hidden opacity-0"
                }`}
              >
                <div className="flex flex-col items-center p-8 bg-white/5 backdrop-blur-lg rounded-lg shadow-xl border border-purple-600">
                  <img
                    src={
                      team.team_logo
                        ? team.team_logo
                        : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
                    }
                    alt={`${team.name} logo`}
                    className="w-24 h-24 rounded-full border-4 border-purple-500 shadow-lg mb-4"
                  />
                  <h3 className="text-2xl font-bold text-purple-300">
                    {team.name}
                  </h3>
                  <div className="flex items-center space-x-2 mt-3 text-yellow-400">
                    <FaTrophy />
                    <span className="font-semibold">Wins: {team.wins}</span>
                  </div>
                  <p className="mt-2 text-red-400">Losses: {team.losses}</p>
                  <p className="text-sm text-gray-500 italic mt-4">
                    Leaderboard Position: #{index + 1}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Carousel Controls */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full text-white hover:bg-purple-500 focus:ring-2 focus:ring-purple-800"
          >
            ❮
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full text-white hover:bg-purple-500 focus:ring-2 focus:ring-purple-800"
          >
            ❯
          </button>
        </div>
      </div>
    </section>
  );
};

export default TopTeamsCarousel;
