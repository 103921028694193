import React from "react";
import { motion } from "framer-motion";
import PageWrapper from "../components/custom/PageWrapper";
import { Link, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaCalendarAlt,
  FaBell,
  FaUserShield,
  FaInfoCircle,
} from "react-icons/fa";

const GetStartedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/team/create");
  };

  return (
    <PageWrapper>
      <div className="flex flex-col justify-start min-h-screen">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-gradient-to-r from-purple-500 to-pink-600 text-white py-6 text-center rounded-lg w-full"
        >
          <h2 className="text-5xl font-bold">Getting Started</h2>
          <p className="mt-4 text-lg max-w-lg mx-auto">
            Your guide to creating teams, scheduling matches, and following best
            practices.
          </p>
        </motion.div>

        <div className="flex flex-col items-center justify-center pt-8">
          {/* Grid Layout for Sections */}
          <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 max-w-6xl w-full">
            {/* Section for Creating a Team */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-purple-500 hover:shadow-xl transition">
              <div className="flex items-center mb-4">
                <FaUsers className="text-purple-400 text-3xl mr-4" />
                <h3 className="text-2xl font-semibold text-purple-500">
                  Creating a Team
                </h3>
              </div>
              <p className="text-gray-300">
                To create a team, go to the{" "}
                <Link
                  to="/team/create"
                  className="text-pink-400 font-bold underline transition duration-200 ease-in-out hover:text-pink-300"
                >
                  Team Creation
                </Link>{" "}
                page. Provide team details, invite members, and manage your
                roster. Only team captains can add or remove members.
              </p>
            </div>

            {/* Section for Scheduling Matches */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-purple-500 hover:shadow-xl transition">
              <div className="flex items-center mb-4">
                <FaCalendarAlt className="text-purple-400 text-3xl mr-4" />
                <h3 className="text-2xl font-semibold text-purple-500">
                  Scheduling Matches
                </h3>
              </div>
              <p className="text-gray-300">
                <Link
                  to="/match/scheduled"
                  className="text-pink-400 font-bold underline transition duration-200 ease-in-out hover:text-pink-300"
                >
                  Scheduled matches
                </Link>{" "}
                can be played within a{" "}
                <span className="text-pink-400 font-bold">
                  15-minute window
                </span>{" "}
                before or after the scheduled time to ensure accurate stats.
              </p>
            </div>

            {/* Section for Inviting Team Members */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-purple-500 hover:shadow-xl transition">
              <div className="flex items-center mb-4">
                <FaUserShield className="text-purple-400 text-3xl mr-4" />
                <h3 className="text-2xl font-semibold text-purple-500">
                  Inviting Team Members
                </h3>
              </div>
              <p className="text-gray-300">
                Team captains can invite members, but{" "}
                <span className="text-pink-400 font-bold">
                  invited members must accept
                </span>{" "}
                the invite before they appear on the team roster. Notifications
                should be enabled for effective match scheduling.
              </p>
            </div>

            {/* Section on Match Notifications */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-purple-500 hover:shadow-xl transition">
              <div className="flex items-center mb-4">
                <FaBell className="text-purple-400 text-3xl mr-4" />
                <h3 className="text-2xl font-semibold text-purple-500">
                  Match Notifications
                </h3>
              </div>
              <p className="text-gray-300">
                Notifications must be accepted for a scheduled match to count.
                Ensure all team members are aware of match times and regularly
                check their notifications.
              </p>
            </div>

            {/* Section on Team Management */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-purple-500 hover:shadow-xl transition">
              <div className="flex items-center mb-4">
                <FaInfoCircle className="text-purple-400 text-3xl mr-4" />
                <h3 className="text-2xl font-semibold text-purple-500">
                  Team Management
                </h3>
              </div>
              <p className="text-gray-300">
                Only team captains have the authority to{" "}
                <span className="text-pink-400 font-bold">
                  add, remove, or delete the team
                </span>
                . Use these privileges responsibly to manage your team
                effectively.
              </p>
            </div>
          </div>

          <button
            onClick={handleClick}
            className="mt-12 btn bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold px-6 py-3 rounded-full shadow-lg hover:shadow-xl hover:from-pink-500 hover:to-purple-500 transition-all duration-200"
          >
            Create Your Team Now
          </button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default GetStartedPage;
