import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const fetchTeams = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/teams`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  return response.data.teams;
};

// Function to delete a specific team by ID
const deleteTeam = async (teamId: number) => {
  await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useTeams = () => {
  const queryClient = useQueryClient();

  // Fetch teams
  const teamsQuery = useQuery({
    queryKey: ["teams"],
    queryFn: fetchTeams,
  });

  // Delete team
  const deleteTeamMutation = useMutation({
    mutationFn: deleteTeam,
    onSuccess: () => {
      // Invalidate and refetch the "teams" query to update the UI after deletion
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });

  return { ...teamsQuery, deleteTeam: deleteTeamMutation.mutate };
};
