import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="py-6 text-gray-400 ">
      <div className="container mx-auto text-center space-y-4">
        <p>© 2024 Prime Championship Circuit. All Rights Reserved.</p>
        <div className="flex justify-center space-x-6">
          <Link to="/privacy" className="hover:text-white transition">
            Privacy Policy
          </Link>
          <Link to="/terms" className="hover:text-white transition">
            Terms of Service
          </Link>
          {/* Add social media icons */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
