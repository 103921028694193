import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useTournaments } from "../hooks/useTournaments";
import PageWrapper from "../components/custom/PageWrapper";
import { Bracket } from "react-brackets";
import { generateSwissPairings } from "../utils/brackets/swissPairings";
import { useProfile } from "../hooks/useProfile";
import { shuffleArray } from "../utils/helper";
import HashLoader from "react-spinners/HashLoader";

interface TeamRecord {
  [key: number]: { wins: number; losses: number };
}

const TournamentDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoadingNextRound, setIsLoadingNextRound] = useState(false);
  const { useTournament, fetchRelevantMatches } = useTournaments();
  const tournamentQuery = useTournament(id || "");
  const { data: profile } = useProfile(undefined);

  const [matchupCounts, setMatchupCounts] = useState<{ [key: string]: number }>(
    {}
  );
  const teamRecordsRef = useRef<TeamRecord>({});
  const [_, setForceRerender] = useState(0); // Dummy state to force re-render
  const [roundPairings, setRoundPairings] = useState<any[][][]>(() => {
    const savedPairings = localStorage.getItem("roundPairings");
    return savedPairings ? JSON.parse(savedPairings) : [];
  });
  const [matchResultsCache, setMatchResultsCache] = useState<{
    [key: string]: any[];
  }>({});
  const [currentRound, setCurrentRound] = useState(() => {
    const savedRound = localStorage.getItem("currentRound");
    return savedRound ? parseInt(savedRound, 10) : 1;
  });

  useEffect(() => {
    localStorage.setItem("teamRecords", JSON.stringify(teamRecordsRef.current));
  }, [teamRecordsRef.current]);

  useEffect(() => {
    localStorage.setItem("roundPairings", JSON.stringify(roundPairings));
  }, [roundPairings]);

  useEffect(() => {
    localStorage.setItem("currentRound", currentRound.toString());
  }, [currentRound]);

  const updateTeamRecords = (teamId: number, isWin: boolean) => {
    const record = teamRecordsRef.current[teamId] || { wins: 0, losses: 0 };
    const updatedRecord = {
      wins: record.wins + (isWin ? 1 : 0),
      losses: record.losses + (!isWin ? 1 : 0),
    };
    teamRecordsRef.current = {
      ...teamRecordsRef.current,
      [teamId]: updatedRecord,
    };

    // Trigger a re-render to reflect changes
    setForceRerender((prev) => prev + 1);
  };

  useEffect(() => {
    if (currentRound === 1 && (tournamentQuery.data?.teams || []).length > 1) {
      setMatchupCounts({});
      const initialPairings = generateSwissPairings(
        tournamentQuery.data?.teams || [],
        matchupCounts
      );
      setRoundPairings([initialPairings]);
    }
  }, [tournamentQuery.data?.teams, currentRound]);

  console.log(tournamentQuery);

  const fetchMatchesForPairings = async () => {
    const updatedCache = { ...matchResultsCache };
    for (const pair of roundPairings[currentRound - 1] || []) {
      if (pair[0] && pair[1]) {
        const team1Players = pair[0].players?.map((p: any) => p.id) || [];
        const team2Players = pair[1].players?.map((p: any) => p.id) || [];
        const cacheKey = `${pair[0].id}-${pair[1].id}`;

        if (!updatedCache[cacheKey]) {
          try {
            const matches = await fetchRelevantMatches(
              team1Players,
              team2Players
            );
            updatedCache[cacheKey] = matches;

            matches.forEach((match) => {
              if (match.winning_team === pair[0].name) {
                updateTeamRecords(pair[0].id, true);
                updateTeamRecords(pair[1].id, false);
              } else if (match.winning_team === pair[1].name) {
                updateTeamRecords(pair[0].id, false);
                updateTeamRecords(pair[1].id, true);
              }
            });
          } catch (error) {
            console.error("Error fetching relevant matches:", error);
          }
        }
      }
    }
    setMatchResultsCache(updatedCache);
  };

  const progressToNextRound = async () => {
    setIsLoadingNextRound(true); // Start loading
    try {
      await fetchMatchesForPairings();
      const sortedTeams = (tournamentQuery.data?.teams || [])
        .map((team) => ({
          ...team,
          wins: teamRecordsRef.current[team.id]?.wins || 0,
          losses: teamRecordsRef.current[team.id]?.losses || 0,
        }))
        .sort((a, b) => b.wins - a.wins || a.losses - b.losses);

      const nextPairings = generateSwissPairings(sortedTeams, matchupCounts);
      setRoundPairings((prevPairings) => [...prevPairings, nextPairings]);
      setCurrentRound((prevRound) => prevRound + 1);
    } catch (error) {
      console.error("Error progressing to the next round:", error);
    } finally {
      setIsLoadingNextRound(false); // End loading
    }
  };

  const resetTournament = () => {
    setCurrentRound(1);
    teamRecordsRef.current = {}; // Clear team records
    setMatchResultsCache({});
    setMatchupCounts({}); // Reset matchup counts

    // Shuffle teams for random initial matchups
    const shuffledTeams = shuffleArray(tournamentQuery.data?.teams || []);

    // Generate new initial pairings for round 1 with shuffled teams
    const initialPairings = generateSwissPairings(shuffledTeams, {});
    setRoundPairings([initialPairings]);

    localStorage.removeItem("currentRound");
    localStorage.removeItem("teamRecords");
    localStorage.removeItem("roundPairings");

    // Trigger a re-render to reflect reset data
    setForceRerender((prev) => prev + 1);
  };

  const rounds = roundPairings.map((pairings, roundIndex) => ({
    title: `Round ${roundIndex + 1}`,
    seeds: pairings.map((pair, index) => ({
      id: index,
      teams: [
        { id: pair[0]?.id || 0, name: pair[0]?.name || "Bye" },
        { id: pair[1]?.id || 0, name: pair[1]?.name || "Bye" },
      ],
    })),
  }));

  return (
    <PageWrapper>
      {tournamentQuery.isLoading ? (
        <div className="flex justify-center items-center h-screen text-white text-2xl">
          Loading tournament details...
        </div>
      ) : tournamentQuery.isError ? (
        <div className="flex justify-center items-center h-screen text-white text-2xl">
          Error loading tournament details
        </div>
      ) : (
        <div className="flex flex-col items-center p-6 text-white min-h-screen">
          <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="space-y-8 md:col-span-1">
              <div className="bg-gray-800/75 p-6 rounded-lg shadow-lg border-2 border-purple-500 text-center">
                <h1 className="text-4xl font-semibold mb-4 text-white">
                  {tournamentQuery.data?.name}
                </h1>
                <p className="text-md text-gray-300 mb-2">
                  {tournamentQuery.data?.description}
                </p>
                <p className="text-lg text-gray-400">
                  <strong>{tournamentQuery.data?.teams?.length}</strong> /{" "}
                  {tournamentQuery.data?.max_teams} teams registered
                </p>
              </div>

              <div className="bg-gray-800/75 p-6 rounded-lg shadow-md border-2 border-purple-500">
                <h2 className="text-2xl font-semibold text-center text-gray-200 mb-6">
                  Teams
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {tournamentQuery.data?.teams?.map((team) => {
                    const record = teamRecordsRef.current[team.id] || {
                      wins: 0,
                      losses: 0,
                    };
                    return (
                      <Link
                        key={team.id}
                        to={`/team/${team.id}`}
                        className="p-4 rounded-lg text-center shadow-sm hover:shadow-md transition-all flex flex-col items-center hover:scale-105"
                        style={{
                          background:
                            "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(31, 41, 55, 0.4))",
                        }}
                      >
                        <img
                          src={
                            team.team_logo ||
                            `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
                          }
                          alt={`${team.name} logo`}
                          className="w-14 h-14 rounded-full mb-4 border-2 border-primary ring-2 ring-offset-4 ring-offset-gray-900 shadow-lg object-cover"
                        />
                        <h3 className="font-medium text-lg text-gray-200">
                          {team.name}
                        </h3>
                        <p className="text-gray-400 text-sm mt-2">
                          Wins: {record.wins}
                        </p>
                        <p className="text-gray-400 text-sm">
                          Losses: {record.losses}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="bg-gray-800/85 p-6 rounded-lg shadow-md border-2 border-purple-500 overflow-x-auto md:col-span-2">
              <h2 className="text-2xl font-semibold text-center text-gray-200 mb-4">
                Swiss Bracket
              </h2>
              {rounds.length >= 1 ? (
                <Bracket rounds={rounds} />
              ) : (
                <p className="text-center text-lg text-gray-400">
                  Not enough teams to display the bracket.
                </p>
              )}

              {(profile?.role === "admin" || profile?.role === "organizer") && (
                <div className="flex justify-center space-x-2 mt-4">
                  <button
                    onClick={progressToNextRound}
                    disabled={isLoadingNextRound}
                    className={`px-4 py-2 font-medium rounded text-sm shadow-md ${
                      isLoadingNextRound
                        ? "bg-blue-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700 text-white"
                    }`}
                  >
                    {isLoadingNextRound ? "Loading..." : "Next Round"}
                  </button>

                  <button
                    onClick={resetTournament}
                    disabled={isLoadingNextRound}
                    className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded text-sm shadow-md"
                  >
                    Reset
                  </button>

                  {isLoadingNextRound && (
                    <div className="flex items-center space-x-2 mt-4">
                      <HashLoader color="#6b46c1" size={20} />
                      <span className="text-purple-500 text-sm">
                        Generating next bracket...
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default TournamentDetailsPage;
