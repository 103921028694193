import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type WebSocketContextProps = {
  messages: string[];
  sendResponse: (response: object) => void;
};

// Add types for the provider's props, including children
type WebSocketProviderProps = {
  url: string;
  children: React.ReactNode; // Define children as ReactNode
};

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined
);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  url,
  children,
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const socketUrl = `${url}?token=${encodeURIComponent(token)}`;
    const socket = new WebSocket(socketUrl);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Message Received: ", message);

      setMessages((prevMessages) => {
        // Use the unique `id` to filter duplicates
        if (prevMessages.some((msg: any) => msg.id === message.id)) {
          console.log("Duplicate message detected:", message);
          return prevMessages;
        }

        // Add the new message to the state
        return [...prevMessages, message];
      });

      // Send acknowledgment back to the server
      socket.send(JSON.stringify({ type: "ack", message: message }));
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket closed: ", event);
    };

    return () => {
      console.log("Closing WebSocket connection");
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [url]); // Depend only on the `url`, not on the entire component re-render

  const sendResponse = (response: object) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(response));
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  return (
    <WebSocketContext.Provider value={{ messages, sendResponse }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
