import React from "react";
import HeroSection from "../components/landing_page/HeroSection";
import FeaturesSection from "../components/landing_page/FeatureSection";
import TopTeamsCarousel from "../components/landing_page/TopTeams";
import Footer from "../components/landing_page/FooterSection";
import PageWrapper from "../components/custom/PageWrapper";

const LandingPage: React.FC = () => {
  return (
    <PageWrapper>
      <div>
        <HeroSection />
        <div className="custom-divider"></div>
        <FeaturesSection />
        <div className="custom-divider"></div>
        <TopTeamsCarousel />
        <div className="custom-divider"></div>
        <Footer />
      </div>
    </PageWrapper>
  );
};

export default LandingPage;
