import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png"; // Import the logo image
import LoginButton from "../components/custom/LoginButton";
import { FaSteam } from "react-icons/fa";
import axios from "axios";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email,
          password,
        }
      );

      const { access_token, refresh_token, user } = response.data;

      // Store tokens and user profile in localStorage
      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("profile", JSON.stringify(user)); // Store user profile

      // Redirect to the welcome page
      navigate("/welcome");
    } catch (err) {
      setError("Invalid username or password. Please try again.");
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Background Image */}
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url('/PredAction.jpg')`, // Replace with your image path
        }}
      />

      {/* Overlay */}
      <div className="absolute inset-0 w-full h-full bg-black opacity-80" />

      {/* Content */}
      <div className="z-10 w-full max-w-md p-8 rounded-lg">
        {/* Logo */}
        <img
          src={logo}
          alt="Logo"
          className="w-24 h-24 mx-auto mb-6 animate-slide-in invert"
        />

        {/* Title */}
        <h1 className="text-2xl md:text-5xl font-bold text-center text-white mb-8 animate-slide-in">
          Prime Championship Circuit
        </h1>

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-3 rounded bg-gray-800 text-white animate-slide-in"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 rounded bg-gray-800 text-white animate-slide-in"
          />
          {error && <p className="text-red-500">{error}</p>}
          <button
            type="submit"
            className="p-3 bg-blue-600 rounded-lg hover:bg-blue-700 text-white hover:scale-105 duration-300 animate-slide-in"
          >
            Login
          </button>
        </form>

        {/* Steam Login Button */}
        <div className="mt-4 min-w-full">
          <LoginButton
            platformName="Steam"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/steam/login`)
            }
            Icon={FaSteam}
            color="bg-gray-700 hover:bg-gray-800"
          />
        </div>

        {/* Register Link */}
        <p className="mt-8 text-center text-white animate-slide-in">
          Don't have an account?{" "}
          <Link
            to="/register"
            className="text-blue-500 underline animate-slide-in"
          >
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
