import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state for better UX

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const tokenFromUrl = query.get("token");
  const refreshTokenFromUrl = query.get("refresh_token");

  const clearAuth = () => {
    setProfile(null);
    setIsAuthenticated(false);
    localStorage.clear();
  };

  const fetchProfile = async (token: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/profile`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Store the profile in localStorage and state
      localStorage.setItem("profile", JSON.stringify(response.data));
      setProfile(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Failed to fetch profile:", error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleAuth = async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      const refreshToken = query.get("refresh_token");
      const profile = query.get("profile");

      if (token && refreshToken) {
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refreshToken);

        if (profile) {
          const parsedProfile = JSON.parse(decodeURIComponent(profile));
          localStorage.setItem("profile", JSON.stringify(parsedProfile));
          setProfile(parsedProfile);
          setIsAuthenticated(true);
          setLoading(false); // Ensure loading is set to false here
        } else {
          await fetchProfile(token);
        }

        navigate("/welcome");
      } else {
        const tokenFromStorage = localStorage.getItem("token");
        if (tokenFromStorage) {
          await fetchProfile(tokenFromStorage);
        } else {
          setLoading(false);
        }
      }
    };

    handleAuth();
  }, [tokenFromUrl, refreshTokenFromUrl, navigate]);

  return {
    clearAuth,
    isAuthenticated,
    profile,
    loading,
  };
};
