import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const fetchNews = async (page: number = 1, limit: number = 10) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/news`,
    {
      params: { page, limit },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useNews = (
  page: number,
  itemsPerPage: number,
  token: string | null
) => {
  return useQuery({
    queryKey: ["news", page, itemsPerPage],
    queryFn: () => fetchNews(page, itemsPerPage),
    enabled: !!token,
    staleTime: 1000 * 60 * 60, // Cache results for 1 hour
    refetchOnWindowFocus: false, // Prevent refetch on window focus
  });
};
