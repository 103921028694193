import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

interface TeamMemberModalProps {
  isOpen: boolean;
  closeModal: () => void;
  teamId: number;
  handleInvite: (userId: number) => void;
}

const TeamMemberModal: React.FC<TeamMemberModalProps> = ({
  isOpen,
  closeModal,
  teamId,
  handleInvite,
}) => {
  const [teamlessUsers, setTeamlessUsers] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      fetchTeamlessUsers();
    }
  }, [isOpen]);

  const fetchTeamlessUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/teamless-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTeamlessUsers(response.data.users);
    } catch (error) {
      toast.error("Error fetching teamless users");
      console.error("Error fetching teamless users", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="card bg-gray-900 shadow-xl w-full max-w-md p-6 relative">
        <button
          className="btn btn-sm btn-circle absolute right-2 top-2"
          onClick={closeModal}
        >
          ✕
        </button>
        <h3 className="text-lg font-bold text-white mb-4">Invite Players</h3>
        <input
          type="text"
          placeholder="Search Players"
          className="input input-bordered w-full mb-4"
        />
        <div className="overflow-y-auto max-h-56 space-y-4">
          {teamlessUsers.map((user) => (
            <div
              key={user.id}
              className="flex items-center justify-between p-2 bg-base-200 rounded"
            >
              <div className="flex items-center space-x-3">
                <img
                  src={user.avatar_url || "/default-avatar.png"}
                  alt="Avatar"
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-white">{user.username}</span>
              </div>
              <button
                onClick={() => handleInvite(user.id)}
                className="btn btn-sm btn-success"
              >
                Invite
              </button>
            </div>
          ))}
        </div>
        <button
          className="btn btn-outline btn-primary mt-4 w-full"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TeamMemberModal;
