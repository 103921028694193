import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useTokenRefresh = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refresh_token");

      if (!token || !refreshToken) {
        navigate("/"); // Redirect to login if tokens are missing
        return;
      }

      // Decode the access token to check its expiration time
      const { exp } = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.floor(Date.now() / 1000);

      // If the token will expire in less than 5 minutes, refresh it
      if (exp - currentTime < 300) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/refresh`,
            {
              refresh_token: refreshToken,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          localStorage.setItem("token", response.data.access_token);
        } catch (err) {
          console.error("Failed to refresh token:", err);
          navigate("/"); // Redirect to login if the refresh fails
        }
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [navigate]);
};
