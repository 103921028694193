import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Custom hook to fetch team matches
export const useTeamMatches = (teamId: number | null) => {
  return useQuery({
    queryKey: ["teamMatches", teamId], // The query key, includes teamId
    queryFn: async () => {
      if (!teamId) return null; // No teamId, no request
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}/matches`, // Your backend endpoint
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you need authorization
          },
        }
      );
      return response.data.matches; // Return the matches data
    },
    enabled: !!teamId, // Only run the query if teamId exists
  });
};
