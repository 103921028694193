import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageWrapper from "../components/custom/PageWrapper";

const TeamResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const teams = location.state?.teams || [];

  const handleTeamClick = (teamId: string) => {
    navigate(`/team/${teamId}`);
  };

  return (
    <PageWrapper>
      <div className="p-8 max-w-screen-lg mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center text-gray-200">
          Team Search Results
        </h2>
        {teams.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            {teams.map((team: any) => (
              <div
                key={team.id}
                onClick={() => handleTeamClick(team.id)}
                className="card border border-purple-500 shadow-lg rounded-2xl hover:shadow-xl transform hover:bg-primary/10 transition-colors duration-300 ease-out flex flex-col items-center p-4 cursor-pointer hover:scale-105"
                style={{
                  background:
                    "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(31, 41, 55, 0.4))",
                  willChange: "transform",
                }}
              >
                {/* Team Logo */}
                <img
                  src={
                    team.team_logo
                      ? team.team_logo
                      : `https://pcclogobucket.s3.us-east-2.amazonaws.com/logos/pcc.jpeg`
                  }
                  alt={`${team.name} logo`}
                  className="w-16 h-16 object-cover rounded-full shadow-lg mt-4 border-2 border-purple-500"
                />

                {/* Team Name and ID */}
                <div className="flex flex-col items-center space-y-2 z-10 mt-4">
                  <h4 className="text-2xl font-semibold text-white">
                    {team.name}
                  </h4>
                  <p className="text-lg text-gray-400">Team ID: {team.id}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center text-xl">No teams found</p>
        )}
      </div>
    </PageWrapper>
  );
};

export default TeamResultsPage;
