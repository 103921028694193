import React from "react";
import Leaderboard from "../components/leaderboard/Leaderboard";
import PageWrapper from "../components/custom/PageWrapper";

const LeaderboardPage: React.FC = () => {
  return (
    <PageWrapper>
      <div className="min-h-scren">
        <Leaderboard />
      </div>
    </PageWrapper>
  );
};

export default LeaderboardPage;
