import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTeams } from "../hooks/useTeams";
import { useProfile } from "../hooks/useProfile";
import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  ListboxButton,
  Transition,
} from "@headlessui/react";
import { motion } from "framer-motion";
import axios from "axios";
import PageWrapper from "../components/custom/PageWrapper";

const DraftLobbyPage: React.FC = () => {
  const navigate = useNavigate();

  const { data: profile, isLoading: profileLoading } = useProfile(undefined);
  const userTeamId = profile?.teamId || null;

  const {
    data: teams,
    isLoading: teamsLoading,
    isError: teamsError,
  } = useTeams();

  const [team2, setTeam2] = useState<number | null>(null);
  const [timerMode, setTimerMode] = useState<string>("per_pick");
  const [timer, setTimer] = useState<number>(30); // Timer for per_pick
  const [bonusTime, setBonusTime] = useState<number>(60); // Bonus time for per_pick
  const [perRoundTime, setPerRoundTime] = useState<number>(30); // Timer for per_round
  const [allowMirrorPicks, setAllowMirrorPicks] = useState(false);
  const [enableBans, setEnableBans] = useState(true);
  const [draftMode, setDraftMode] = useState("competitive");

  const startDraft = async (
    team1_id: number,
    team2_id: number,
    timerMode: string,
    timer: number,
    bonusTime: number,
    perRoundTime: number,
    mirror: boolean,
    bans: boolean,
    mode: string
  ) => {
    if (!team1_id || !team2_id) {
      alert("Please select both teams before starting the draft.");
      return;
    }

    const params = {
      timer_mode: timerMode,
      timer: timer.toString(),
      bonus_time: bonusTime.toString(),
      per_round_time: perRoundTime.toString(),
      mirror: mirror.toString(),
      bans: bans.toString(),
      mode,
    };

    const queryString = new URLSearchParams(params).toString();
    const draftURL = `/draft/${team1_id}/${team2_id}?${queryString}`;

    // Retrieve the authorization token from local storage
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Authorization token is missing. Please log in.");
      return;
    }

    try {
      // Notify the backend of the draft creation using Axios
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/notify-draft`,
        {
          team1_id,
          team2_id,
          draft_url: draftURL,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );

      console.log("Draft creation notification sent to the backend.");
    } catch (error) {
      console.error("Failed to notify backend of draft creation:", error);
      alert("Failed to create draft. Please try again.");
    }

    // Navigate to the draft page
    navigate(draftURL);
  };

  if (profileLoading || teamsLoading) return <div>Loading...</div>;
  if (teamsError) return <div>Error fetching teams</div>;

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col justify-center items-center">
        <motion.div
          className="container mx-auto p-6 bg-white/5 backdrop-blur-lg shadow-xl max-w-5xl border border-purple-500 rounded-xl"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900 dark:text-white">
            Draft Lobby
          </h1>

          {/* Draft and Team Selection */}
          <div className="grid grid-cols-2 gap-6 mb-8">
            {/* Draft Mode */}
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Draft Mode
              </label>
              <Listbox value={draftMode} onChange={setDraftMode}>
                <div className="relative">
                  <ListboxButton className="relative w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600 focus:ring-4 focus:ring-indigo-500 transition">
                    <span className="block truncate dark:text-white">
                      {draftMode.charAt(0).toUpperCase() + draftMode.slice(1)}
                    </span>
                  </ListboxButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <ListboxOptions className="absolute z-10 w-full mt-1 max-h-60 bg-white dark:bg-gray-700 overflow-auto rounded-lg shadow-lg">
                      {["competitive", "competitive-beta", "casual"].map(
                        (mode) => (
                          <ListboxOption
                            key={mode}
                            value={mode}
                            className={({ active, selected }) =>
                              `cursor-pointer select-none relative p-3 transition-all duration-300 ${
                                selected
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900 dark:text-gray-300"
                              } ${
                                active
                                  ? "bg-purple-400 hover:bg-purple-500 dark:bg-purple-700"
                                  : ""
                              }`
                            }
                          >
                            {mode.charAt(0).toUpperCase() + mode.slice(1)}
                          </ListboxOption>
                        )
                      )}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Listbox>
            </div>

            {/* Opponent Team */}
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Select Opponent Team
              </label>
              <Listbox value={team2} onChange={setTeam2}>
                <div className="relative">
                  <ListboxButton className="relative w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600 focus:ring-4 focus:ring-indigo-500 transition">
                    <span className="block truncate dark:text-white">
                      {team2
                        ? teams.find((team: any) => team.id === team2)?.name
                        : "Choose Opponent Team"}
                    </span>
                  </ListboxButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <ListboxOptions className="absolute z-10 w-full mt-1 max-h-60 bg-white dark:bg-gray-700 overflow-auto rounded-lg shadow-lg">
                      {teams
                        .filter((team: any) => team.id !== userTeamId)
                        .map((team: any) => (
                          <ListboxOption
                            key={team.id}
                            value={team.id}
                            className={({ active, selected }) =>
                              `cursor-pointer select-none relative p-3 transition-all duration-300 ${
                                selected
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900 dark:text-gray-300"
                              } ${
                                active
                                  ? "bg-purple-400 hover:bg-purple-500 dark:bg-purple-700"
                                  : ""
                              }`
                            }
                          >
                            {team.name}
                          </ListboxOption>
                        ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>

          {/* Timer Settings */}
          <div className="grid grid-cols-3 gap-6 mb-8">
            {/* Timer Mode */}
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Timer Mode
              </label>
              <Listbox value={timerMode} onChange={setTimerMode}>
                <div className="relative">
                  <ListboxButton className="relative w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600 focus:ring-4 focus:ring-indigo-500 transition">
                    <span className="block truncate dark:text-white">
                      {timerMode === "per_pick" ? "Per Pick" : "Per Round"}
                    </span>
                  </ListboxButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <ListboxOptions className="absolute z-10 w-full mt-1 max-h-60 bg-white dark:bg-gray-700 overflow-auto rounded-lg shadow-lg">
                      {["per_pick", "per_round"].map((mode) => (
                        <ListboxOption
                          key={mode}
                          value={mode}
                          className={({ active, selected }) =>
                            `cursor-pointer select-none relative p-3 transition-all duration-300 ${
                              selected
                                ? "bg-blue-500 text-white"
                                : "text-gray-900 dark:text-gray-300"
                            } ${
                              active
                                ? "bg-purple-400 hover:bg-purple-500 dark:bg-purple-700"
                                : ""
                            }`
                          }
                        >
                          {mode === "per_pick" ? "Per Pick" : "Per Round"}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Listbox>
            </div>

            {/* Timer Value */}
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Timer
              </label>
              <input
                type="number"
                value={timer}
                onChange={(e) => setTimer(Number(e.target.value))}
                className="w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600"
              />
            </div>

            {/* Bonus Time */}
            {(timerMode === "per_pick" || timerMode === "per_round") && (
              <div>
                <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                  Bonus Time
                </label>
                <input
                  type="number"
                  value={bonusTime}
                  onChange={(e) => setBonusTime(Number(e.target.value))}
                  className="w-full p-3 rounded-lg border-2 border-gray-300 dark:border-gray-600"
                />
              </div>
            )}
          </div>

          {/* Options */}
          <div className="grid grid-cols-2 gap-6 mb-8">
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Allow Mirror Picks
              </label>
              <input
                type="checkbox"
                checked={allowMirrorPicks}
                onChange={(e) => setAllowMirrorPicks(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2 text-gray-900 dark:text-white">
                Enable Bans
              </label>
              <input
                type="checkbox"
                checked={enableBans}
                onChange={(e) => setEnableBans(e.target.checked)}
                className="form-checkbox h-5 w-5 text-purple-500"
              />
            </div>
          </div>

          <div className="flex justify-center">
            {/* Start Draft Button */}
            <button
              onClick={() =>
                startDraft(
                  userTeamId!,
                  team2!,
                  timerMode,
                  timer,
                  bonusTime,
                  perRoundTime,
                  allowMirrorPicks,
                  enableBans,
                  draftMode
                )
              }
              className="min-w-[500px] btn btn-outline btn-primary hover:scale-105 hover:shadow-glow"
              disabled={!team2}
            >
              Start Draft
            </button>
          </div>
        </motion.div>
      </div>
    </PageWrapper>
  );
};

export default DraftLobbyPage;
