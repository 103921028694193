import React from "react";
import PatchNotes from "../components/patch_notes/PatchNotes";
import PageWrapper from "../components/custom/PageWrapper";

const PatchNotesPage: React.FC = () => {
  return (
    <PageWrapper>
      <div className="p-6 space-y-12">
        <PatchNotes />
      </div>
    </PageWrapper>
  );
};

export default PatchNotesPage;
