import React from "react";
import { getRankImage } from "../../utils/rankUtils";

interface RankInfoProps {
  rank?: string;
  vpTotal?: number;
  size?: "small" | "medium" | "large" | "extraLarge"; // Add a size prop for flexibility
}

const RankInfo: React.FC<RankInfoProps> = ({
  rank = "Unranked",
  vpTotal = 0,
  size = "medium", // Default size if not specified
}) => {
  const rankImage = getRankImage(rank);

  // Adjust image and text size based on the size prop
  const imageSize =
    size === "small"
      ? "w-12 h-12"
      : size === "large"
      ? "w-24 h-24"
      : size == "extraLarge"
      ? "w-full h-full object-contain"
      : "w-16 h-16";
  const textSize =
    size === "small"
      ? "text-sm"
      : size === "large"
      ? "text-lg"
      : size === "extraLarge"
      ? "flex items-center justify-center font-bold text-2xl pb-4"
      : "text-lg";

  return (
    <div className="relative flex flex-col items-center">
      <div className={`relative ${imageSize}`}>
        <img src={rankImage} alt={rank} className="object-contain" />
        <div
          className={`absolute inset-0 flex items-center justify-center font-bold ${textSize} text-white`}
        >
          {vpTotal}
        </div>
      </div>
      <h3 className={`font-bold ${textSize} text-center mb-2 text-gray-200`}>
        {rank}
      </h3>
    </div>
  );
};

export default RankInfo;
