import { motion } from "framer-motion";
import { useTournaments } from "../hooks/useTournaments";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../hooks/useProfile";
import PageWrapper from "../components/custom/PageWrapper";
import { TrophyIcon, UsersIcon } from "@heroicons/react/24/solid";

const TournamentsPage = () => {
  const { tournamentsQuery, registerTeamMutation } = useTournaments();
  const { data: profile } = useProfile(undefined);
  const navigate = useNavigate();

  if (tournamentsQuery.isLoading) return <div>Loading tournaments...</div>;
  if (tournamentsQuery.isError) return <div>Error loading tournaments</div>;

  const handleRegister = (tournamentId: number) => {
    const teamId = profile.teamId;
    registerTeamMutation.mutate({ tournamentId, teamId });
  };

  return (
    <PageWrapper>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="bg-gradient-to-r from-purple-500 to-pink-600 text-white py-10 text-center rounded-lg mb-8 w-full shadow-md"
      >
        <h2 className="text-4xl font-bold">Unite. Compete. Dominate.</h2>
        <p className="mt-4 text-lg">
          Rally your team, join the tournaments, and rise to the top of the
          Prime Championship Circuit!
        </p>
      </motion.div>
      <div className="container mx-auto p-6">
        {/* Tournaments Section */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-purple-500">Tournaments</h1>
          {(profile?.role === "admin" || profile?.role === "organizer") && (
            <button
              onClick={() => navigate("/create-tournament")}
              className="btn btn-outline btn-primary shadow-lg transition hover:scale-105"
            >
              Create Tournament
            </button>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {tournamentsQuery.data?.map((tournament) => (
            <div
              key={tournament.id}
              className="card shadow-lg w-full max-w-2xl mb-8 rounded-2xl transform transition-all duration-300 ease-out flex flex-col items-center p-3 hover:scale-105 cursor-pointer"
              style={{
                background:
                  "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(157, 80, 229, 0.1))",
                boxShadow: "0 4px 10px rgba(139, 92, 246, 0.4)", // soft purple outer glow
              }}
              onClick={() => navigate(`/tournaments/${tournament.id}`)}
            >
              <div className="card-body">
                <h2 className="card-title flex items-center space-x-2">
                  <TrophyIcon className="w-6 h-6 text-yellow-300" />
                  <span>{tournament.name}</span>
                </h2>
                <p className="mt-2 text-sm text-gray-200">
                  {tournament.description || "No description provided."}
                </p>
                <div className="flex items-center mt-4 space-x-2">
                  <UsersIcon className="w-5 h-5 text-blue-300" />
                  <p>
                    {tournament.teams?.length}/{tournament.max_teams} teams
                    registered
                  </p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRegister(tournament.id);
                  }}
                  className="mt-4 btn btn-outline btn-secondary text-white transition hover:bg-purple-800"
                >
                  Register Team
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default TournamentsPage;
