import { useState, useEffect } from "react";
import axios from "axios";

const useTeamStats = (teamId: number) => {
  const [teamStats, setTeamStats] = useState({
    gamesPlayed: 0,
    totalKills: 0,
    totalDeaths: 0,
    totalAssists: 0,
    winLossRatio: 0,
    averageKDA: 0,
    totalWardsPlaced: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/team/${teamId}/stats`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setTeamStats(response.data.stats || teamStats);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching team stats:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    if (teamId) {
      fetchTeamStats();
    }
  }, [teamId]);

  return { teamStats, isLoading, isError };
};

export default useTeamStats;
